import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, FormControlLabel, Grid, Checkbox, TextField, InputAdornment, Select, MenuItem, colors, Button } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import { ConfigContext } from '../utils/ConfigContext';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const OilGas_Rates = forwardRef(({ sx, clients, currency, selectedPlans,
  setSelectedPlans, loadingStatus, response,
  addToPendingTasks, discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy, selectedOptions, addSelectedOption,
  setViewSelectedOption, paymentFrequency, specificCurrency, setSpecificCurrency,
  specificPaymentFrequency, setSpecificPaymentFrequency }, ref) => {

  const { appConfig } = useContext(ConfigContext);

  const id = 'oil_gas';

  const containerRef = useRef(null);

  const oilGas_plans = ['oil_gas_essential', 'oil_gas_classic', 'oil_gas_gold'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.set1_area_of_coverage);

  const currencies = getSafe(() => appConfig.content.currencies, []);

  const [assistanceCovers, setAssistanceCovers] = useState({
    evacuation: false,
    repatriation: false
  });

  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);
  }, [response, discounts, paymentFrequency]);

  useEffect(() => {
    addSelectedOption(id, 'specific_area_of_coverage', specificAreaOfCoverage[id]);
    addSelectedOption(id, 'specific_currency', specificCurrency[id]);
    addSelectedOption(id, 'specific_payment_frequency', specificPaymentFrequency[id]);
    addSelectedOption(id, 'discount', discounts[id] == '' ? 0 : discounts[id]);
    addSelectedOption(id, 'evacuation', assistanceCovers.evacuation);
    addSelectedOption(id, 'repatriation', assistanceCovers.repatriation);
  }, [assistanceCovers.evacuation, assistanceCovers.repatriation, specificAreaOfCoverage[id], specificCurrency[id], specificPaymentFrequency[id], discounts[id]]);

  useEffect(() => {
    setViewSelectedOption(id, 'discount', (option_value) => {
      discounts[id] = option_value;
      setDiscounts(discounts);
    });

    setViewSelectedOption(id, 'specific_area_of_coverage', (option_value) => {
      specificAreaOfCoverage[id] = option_value;
      setSpecificAreaOfCoverage(specificAreaOfCoverage);
    });

    setViewSelectedOption(id, 'specific_currency', (option_value) => {
      specificCurrency[id] = option_value;
      setSpecificCurrency(specificCurrency);
    });

    setViewSelectedOption(id, 'specific_payment_frequency', (option_value) => {
      specificPaymentFrequency[id] = option_value;
      setSpecificPaymentFrequency(specificPaymentFrequency);
    });

    setViewSelectedOption(id, 'evacuation', (option_value) => {
      assistanceCovers.evacuation = option_value == 1;
      setAssistanceCovers(assistanceCovers);
    });

    setViewSelectedOption(id, 'repatriation', (option_value) => {
      assistanceCovers.repatriation = option_value == 1;
      setAssistanceCovers(assistanceCovers);
    });
  }, [selectedOptions]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;


    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'essential': computedRate('essential', rates[i].essential) * totalPercent,
        'classic': computedRate('classic', rates[i].classic) * totalPercent,
        'gold': computedRate('gold', rates[i].gold) * totalPercent,
      })
    }

    return computedRates;
  }

  function computeOilGasRates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates.client,
        'currency': rates[i].currency,
        'essential': computedRate('essential', rates[i].essential),
        'classic': computedRate('classic', rates[i].classic),
        'gold': computedRate('gold', rates[i].gold),
      })
    }

    return computedRates;
  }

  function computedRate(plan, rate) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    let total = rate;

    // const payment_frequency_divided = getPaymentFrequencyDivided();

    // total /= payment_frequency_divided;


    return total;
  }

  function getPaymentFrequencyDivided() {
    if (paymentFrequency === 'Annually') {
      return 1;
    } else if (paymentFrequency === 'Semi Annually') {
      return 2;
    } else if (paymentFrequency === 'Quarterly') {
      return 4;
    } else if (paymentFrequency === 'Monthly') {
      return 12;
    }
  }

  function checkIfThereIsSelectedPlan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (oilGas_plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function scrollToView() {
    // setTimeout(() => {
    //   containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // }, 200);
  }

  function onInputDiscountChange(e) {
    let value = e.target.value;

    // Allow only numbers and at most one decimal point
    value = value.replace(/[^0-9.]/g, '');

    // Prevent multiple decimals
    if ((value.match(/\./g) || []).length > 1) {
      value = value.substring(0, value.lastIndexOf('.'));
    }

    // Ensure only two decimal places
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      value = `${integerPart}.${decimalPart.substring(0, 2)}`;
    }

    // Remove leading zeros (but allow "0" or "0.x" scenarios)
    if (value !== '' && value !== '0' && value.startsWith('0') && !value.startsWith('0.')) {
      value = value.replace(/^0+/, '');
    }

    // Limit value to a maximum of 100
    if (parseFloat(value) > 100) {
      value = '100';
    }

    discounts[id] = value;

    setDiscounts({ ...discounts });
  }



  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2, scrollMarginTop: '200px' }} variant='outlined' ref={containerRef}>
        <Typography variant='h5' sx={{ color: '#fff', background: '#0d47a1', px: 2, py: 1 }}>BUPA Oil & Gas</Typography>
        <Box sx={{ p: 2 }}>
          {/* <Grid container alignItems='center'>
            <Grid container alignItems='center'>
              <Grid item xs={1.5} mr={2}><Typography fontSize={18} fontWeight={600}>Select Plans</Typography></Grid>

              <Grid item xs={1} mr={2}>
                <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_essential' />} label="Essential" />
              </Grid>
              <Grid item xs={1} mr={2}>
                <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_classic' />} label="Classic  " />
              </Grid>
              <Grid item xs={1} mr={2}>
                <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_gold' />} label="Gold" />
              </Grid>

              <Grid item xs={1}>
                <Typography fontSize={18} fontWeight={600}>Optional</Typography>
              </Grid>
              <Grid item xs={1} mr={2}>
                <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                  onChange={(e) => {
                    setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked });

                    scrollToView();
                  }} />}
                  label="Evacuation" />
              </Grid>
              <Grid item xs={1} mr={2}>
                <FormControlLabel control={<Checkbox checked={assistanceCovers.repatriation}
                  onChange={(e) => {
                    setAssistanceCovers({ ...assistanceCovers, repatriation: e.target.checked });
                    setTimeout(() => {
                      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }, 200);
                  }} />}
                  label="Repatriation" />
              </Grid>
            </Grid>
            <Grid item xs={1.5} mr={2}><Typography fontSize={18} fontWeight={600}>Area Of Coverage</Typography></Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px' }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
            <Grid item xs={1.5} mr={2}><Typography fontSize={18} fontWeight={600}>Renewal/Further Discount</Typography></Grid>
            <Grid item xs={1}>
              <TextField
                size='small'
                sx={{ width: '80px', textAlign: 'end' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discounts[id]}
                onChange={onInputDiscountChange}
              />
            </Grid>
          </Grid> */}

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_essential' />} label="Essential" />
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_classic' />} label="Classic  " />
              <FormControlLabel control={<SelectPlanCheckbox onChangeCb={scrollToView} selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='oil_gas_gold' />} label="Gold" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Assistance Cover</Typography>
              <FormControlLabel control={<Checkbox checked={assistanceCovers.evacuation}
                onChange={(e) => {
                  setAssistanceCovers({ ...assistanceCovers, evacuation: e.target.checked });

                  scrollToView();
                }} />}
                label="Evacuation" />
              <FormControlLabel control={<Checkbox checked={assistanceCovers.repatriation}
                onChange={(e) => {
                  setAssistanceCovers({ ...assistanceCovers, repatriation: e.target.checked });
                  setTimeout(() => {
                    containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }, 200);
                }} />}
                label="Repatriation" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area Of Coverage</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                    ))
                  }

                </Select>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Currency</Typography>
                <Select
                  size='small'
                  value={specificCurrency[id]}
                  onChange={(e) => {
                    specificCurrency[id] = e.target.value;
                    setSpecificCurrency({ ...specificCurrency });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    currencies && currencies.map(currency => (
                      <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                    ))
                  }

                </Select>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Payment Frequency</Typography>
                <Select
                  size='small'
                  value={specificPaymentFrequency[id]}
                  onChange={(e) => {
                    specificPaymentFrequency[id] = e.target.value;
                    setSpecificPaymentFrequency({ ...specificPaymentFrequency });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    ["Annually", "Semi Annually", "Quarterly", "Monthly"].map(payment_frequency => (
                      <MenuItem key={payment_frequency} value={payment_frequency}>{payment_frequency}</MenuItem>
                    ))
                  }

                </Select>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>

          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-oil-gas-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-oil-gas-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#0d47a1' }}>
                <TableRow>
                  <TableCell sx={{ width: 260, fontWeight: 600, color: '#fff' }}>Plan</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div>  </TableCell>
                    })
                  }
                  <TableCell sx={{ fontWeight: 600, color: '#fff' }}>Subtotal</TableCell>
                </TableRow>


              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='oil_gas_essential'
                  planName='essential'
                  planLabel='Essential'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#85b1f5'
                  loading={loadingStatus[id]}
                  rates={response ? computeOilGasRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('oil_gas_essential')}
                />
                <TableRatesRow
                  planId='oil_gas_classic'
                  planName='classic'
                  planLabel='Classic'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#85b1f5'
                  loading={loadingStatus[id]}
                  rates={response ? computeOilGasRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('oil_gas_classic')}
                />
                <TableRatesRow
                  planId='oil_gas_gold'
                  planName='gold'
                  planLabel='Gold'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#85b1f5'
                  loading={loadingStatus[id]}
                  rates={response ? computeOilGasRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('oil_gas_gold')}
                />
                {
                  (assistanceCovers.evacuation || assistanceCovers.repatriation) &&
                  <TableRow sx={{ backgroundColor: '#fff' }}>
                    <TableCell colSpan={clients.length + 2} sx={{ fontWeight: 600, backgroundColor: '#fff' }}>Optional</TableCell>
                  </TableRow>
                }

                {/* <TableRow>
                  <TableCell colSpan={clients.length + 2} sx={{ fontWeight: 600 }}>Optional</TableCell>
                </TableRow> */}

                <TableRatesRow
                  planId='oil_gas_evacuation'
                  planName='evacuation'
                  planLabel='Evacuation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  visible={assistanceCovers.evacuation}
                />

                <TableRatesRow
                  planId='oil_gas_repatriation'
                  planName='repatriation'
                  planLabel='Repatriation (Annually)'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#eeeeee'
                  loading={loadingStatus[id]}
                  rates={response ? response.assistance_covers : []}
                  currency={currency}
                  visible={assistanceCovers.repatriation}
                />
                {/* 
                {
                  checkIfThereIsSelectedPlan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default OilGas_Rates;