import { Box, Button, Typography } from '@mui/material';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service or console
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {

      return <>
        <Box sx={{ mt: 35 }}>
          <Typography sx={{ fontSize: 32, textAlign: 'center' }}>Medishure Quote Tool</Typography>
          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant='contained'
              
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >Refresh Page</Button>
          </Box>
        </Box>
      </>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;