import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Chip, CircularProgress, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function AppFileCardClassic({ sx, id, title, fileId, setFileId, setFile, uploadButtonTitle, downloadButtonTitle, isUploading,
  setShowUploadDialog, getLastUpdate, downloadFile, fetchingFiles, setFileDescription, fileType = 'excel' }) {

  function getInputAccept() {
    if (fileType === 'excel') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    } else if (fileType === 'text') {
      return '.txt'
    } else if (fileType === 'json') {
      return '.json'
    }
  }

  return (
    <Card variant="outlined" sx={{ p: 2, ...sx }}>
      <Typography variant='h6' sx={{ color: '#42a5f5' }}>{title}</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Button onClick={() => {
          setFile(null);
          setFileDescription(title);
        }} size='small' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
          {uploadButtonTitle}
          {
            (isUploading === true && fileId === id) &&
            <CircularProgress size='18px' sx={{ color: '#fff', ml: 1 }} />
          }

          <VisuallyHiddenInput type="file" accept={getInputAccept()} onChange={(e) => {
            setFile(e.target.files[0]);
            setFileId(id);
            setShowUploadDialog(false);

            e.target.value = null;
          }}
          />
        </Button>
        <Chip label={downloadButtonTitle}
          onClick={() => { downloadFile(id); }}
          icon={<FileDownloadIcon />}
          sx={{ ml: 2 }} />
      </Box>
      <Box sx={{ mt: 2 }}>
        {
          fetchingFiles ? <CircularProgress size={20} /> :
            <Typography>Last Update: {getLastUpdate(id)}</Typography>
        }
      </Box>

    </Card>
  );
}