
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Autocomplete, colors, IconButton, MenuItem, Select, TextField } from '@mui/material';
import countries from '../components/Countries_Data';
import { forwardRef, useImperativeHandle, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';



const InputTable = forwardRef(({ clients, setClients, area_of_coverages, currencies, relationships, setCurrency,
  setPaymentFrequency, setAreaOfCoverage, setCountryOfResidence, setNationality, setShowRefreshNow, isShowingResult,
  setShowRemoveClient, setRemoveClientIndex }, ref) => {

  // const [clientsStaging, setClientsStaging] = useState([]);
  // const [clientsStagingStr, setClientsStagingStr] = useState([]);

  // const [debouncedClientsStaging] = useDebounce(clientsStagingStr, 1500);

  // useEffect(() => {
  //   console.log('ok');

  //   if (clientsStaging.length === 0) return;

  //   for (let i = 0; i < clients.length; i++) {
  //     if (!clientsStaging[i]) continue;

  //     if (clientsStaging[i].hasOwnProperty('full_name')) {
  //       clients[i].full_name = clientsStaging[i].full_name;
  //     }

  //     if (clientsStaging[i].hasOwnProperty('age')) {
  //       clients[i].age = clientsStaging[i].age;
  //     }
  //   }

  //   console.log(clients);

  //   setClients(clients);


  // }, [debouncedClientsStaging]);

  const [formErrors, setFormErrors] = useState([]);

  useImperativeHandle(ref, () => ({


    getClientData: function () {
      const _clients = JSON.parse(JSON.stringify(clients));

      for (let i = 0; i < clients.length; i++) {
        const clientFullNameInput = document.getElementById('client-full-name-' + i);
        const clientAgeInput = document.getElementById('client-age-' + i);

        _clients[i]['full_name'] = clientFullNameInput.value;
        _clients[i]['age'] = clientAgeInput.value;
      }

      setClients([..._clients]);

      return _clients;
    },

    validateForm: function () {
      const _clients = ref.current.getClientData();

      const errors = [];

      for (let i = 0; i < _clients.length; i++) {
        const full_name = _clients[i].full_name.trim();
        const age = typeof _clients[i].age === 'string' ? _clients[i].age.trim() : _clients[i].age;
        const gender = _clients[i].gender.trim();
        const area_of_coverage = _clients[i].area_of_coverage.trim();
        const nationality = _clients[i].nationality.trim();
        const country_of_residence = _clients[i].country_of_residence.trim();
        const relationship = _clients[i].relationship.trim();

        if (full_name === '') {
          errors.push({
            field: 'full_name',
            index: i,
            message: 'Full Name is required'
          });
        }

        if (age === '') {
          errors.push({
            field: 'age',
            index: i,
            message: 'Age is required'
          });
        }

        if (gender === '') {
          errors.push({
            field: 'gender',
            index: i,
            message: 'Gender is required'
          });
        }

        if (area_of_coverage === '') {
          errors.push({
            field: 'area_of_coverage',
            index: i,
            message: 'Area of Coverage is required'
          });
        }

        if (nationality === '') {
          errors.push({
            field: 'nationality',
            index: i,
            message: 'Nationality is required'
          });
        }

        if (country_of_residence === '') {
          errors.push({
            field: 'country_of_residence',
            index: i,
            message: 'Country of Residence is required'
          });
        }

        if (relationship === '') {
          errors.push({
            field: 'relationship',
            index: i,
            message: 'Relationship is required'
          });
        }
      }

      setFormErrors(errors);

      return errors.length === 0;
    }
  }));

  function handleSelectChange(event, index, field, inputType) {
    const _clients = JSON.parse(JSON.stringify(clients));

    let val = event.target.value;


    if (field === 'currency') {
      setCurrency(val);
    } else if (field === 'payment_frequency') {
      setPaymentFrequency(val);
    } else if (field === 'area_of_coverage') {
      setAreaOfCoverage(val);
    }

    if (field === 'area_of_coverage' || field === 'currency' || field === 'payment_frequency' || field === 'country_of_residence') {
      for (let i = 0; i < _clients.length; i++) {
        _clients[i][field] = val;
      }
    } else {
      _clients[index][field] = val;
    }

    setClients([..._clients]);
  }

  function handleAutocompleteItemChange(index, field, value) {
    const _clients = JSON.parse(JSON.stringify(clients));


    if (field === 'country_of_residence') {
      // for (let i = 0; i < _clients.length; i++) {
      //   _clients[i][field] = value;
      // }
      setCountryOfResidence(value);
    } if (field === 'nationality') {   
      setNationality(value);
    }

    _clients[index][field] = value;

    setClients([..._clients]);
  }

  // function handleInputOnChange(e, index, field) {
  //   if(!clientsStaging[index]) {
  //     clientsStaging[index] = {
  //       full_name: clients[index].full_name,
  //       age: clients[index].age
  //     };  
  //   }

  //   clientsStaging[index][field] = e.target.value;

  //   // console.log(clientsStaging);

  //   setClientsStaging(clientsStaging);
  //   setClientsStagingStr(JSON.stringify(clientsStaging));
  // }

  function getFormError(field, index) {
    for (let i = 0; i < formErrors.length; i++) {
      if (formErrors[i].field === field && formErrors[i].index === index) {
        return formErrors[i];
      }
    }

    return null;
  }

  function getFormErrorMessage(field, index) {
    for (let i = 0; i < formErrors.length; i++) {
      if (formErrors[i].field === field && formErrors[i].index === index) {
        return true;
      }
    }

    return false;
  }



  return (
    <TableContainer component={Paper} sx={{ width: '100%', overflowX: 'auto', paddingBottom: 2, paddingTop: 2 }} elevation={3}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Area Of Coverage</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Payment Frequency</TableCell>
            <TableCell>Nationality</TableCell>
            <TableCell>Country Of Residence</TableCell>
            <TableCell>Relationship</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            clients.map((client, idx) => (
              <TableRow key={idx + '-' + client['full_name']}>
                <TableCell sx={{ width: '40px', paddingRight: 0 }}>
                  <IconButton onClick={() => {
                    setShowRemoveClient(true);
                    setRemoveClientIndex(idx);
                  }}>
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ minWidth: '180px', paddingRight: 0 }}>
                  <TextField
                    id={'client-full-name-' + idx}
                    defaultValue={client['full_name']}
                    size='small'
                    fullWidth
                    inputProps={{ style: { fontSize: 12, height: '22px' } }}
                    error={getFormErrorMessage('full_name', idx)}
                  />
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '60px' }}>
                  <TextField
                    id={'client-age-' + idx}
                    defaultValue={client['age']}
                    size='small'
                    fullWidth
                    sx={{ width: '60px', margin: 0 }}
                    inputProps={{ style: { fontSize: 12, height: '22px' } }}
                    onChange={(e) => {
                      var numericValue = e.target.value.replace(/[^0-9]/g, '');

                      // Allow a single zero but remove leading zeros from other values
                      if (numericValue.length > 1) {
                        numericValue = numericValue.replace(/^0+/, '');
                      }

                      if (numericValue > 120) {
                        numericValue = 120;
                      }

                      e.target.value = numericValue;

                      if (isShowingResult) {
                        setShowRefreshNow(true);
                      }
                    }}
                    error={getFormErrorMessage('age', idx)}
                  />
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '90px' }}>
                  <Select
                    size='small'
                    value={client['gender']}
                    onChange={(e) => {
                      handleSelectChange(e, idx, 'gender', 'select');
                    }}
                    sx={{ width: '90px', fontSize: 12, height: 40 }}
                    fullWidth
                    error={getFormErrorMessage('gender', idx)}
                  >
                    <MenuItem value='Male'>Male</MenuItem>
                    <MenuItem value='Female'>Female</MenuItem>
                  </Select>
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '190px' }}>
                  <Select
                    size='small'
                    value={client['area_of_coverage']}
                    onChange={(e) => {
                      handleSelectChange(e, idx, 'area_of_coverage', 'select');
                    }}
                    sx={{ fontSize: 12, height: 40, width: '190px' }}
                    fullWidth
                    error={getFormErrorMessage('area_of_coverage', idx)}
                  >
                    {
                      area_of_coverages.map(area_of_coverage => (
                        <MenuItem key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                      ))
                    }

                  </Select>
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '85px' }}>
                  <Select
                    size='small'
                    value={client['currency']}
                    onChange={(e) => {
                      handleSelectChange(e, idx, 'currency', 'select');
                    }}
                    sx={{ width: '85px', fontSize: 12, height: 40 }}
                    fullWidth
                  >
                    {
                      currencies.map(currency => (
                        <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                      ))
                    }
                  </Select>
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '130px' }}>
                  <Select
                    size='small'
                    value={client['payment_frequency']}
                    onChange={(e) => {
                      handleSelectChange(e, idx, 'payment_frequency', 'select');
                    }}
                    sx={{ width: '130px', fontSize: 12, height: 40 }}
                    fullWidth
                  >
                    <MenuItem value='Annually'>Annually</MenuItem>
                    <MenuItem value='Semi Annually'>Semi Annually</MenuItem>
                    <MenuItem value='Quarterly'>Quarterly</MenuItem>
                    <MenuItem value='Monthly'>Monthly</MenuItem>

                  </Select>
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '140px' }}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    sx={{ width: '140px' }}
                    disableClearable={true}
                    renderInput={(params) => <TextField  {...params}
                      error={getFormErrorMessage('nationality', idx)}
                    />}
                    size='small'
                    value={client['nationality']}
                    onChange={(event, newValue) => {
                      handleAutocompleteItemChange(idx, 'nationality', newValue);
                    }}
                    fullWidth
                  />
                </TableCell>
                <TableCell sx={{ paddingRight: 0, width: '140px' }}>
                  <Autocomplete
                    disablePortal
                    options={countries}
                    sx={{ width: '140px' }}
                    disableClearable={true}
                    renderInput={(params) => {
                      return <TextField {...params} error={getFormErrorMessage('country_of_residence', idx)} />
                    }
                    }
                    size='small'
                    value={client['country_of_residence']}
                    onChange={(event, newValue) => {
                      handleAutocompleteItemChange(idx, 'country_of_residence', newValue);
                    }}
                    fullWidth

                  />
                </TableCell>
                <TableCell sx={{ paddingRight: 2, width: '140px' }}>
                  <Autocomplete
                    disablePortal
                    options={relationships}
                    sx={{ width: '140px' }}
                    disableClearable={true}
                    renderInput={(params) => <TextField  {...params}
                      error={getFormErrorMessage('relationship', idx)}
                    />}
                    size='small'
                    value={client['relationship']}
                    onChange={(event, newValue) => {
                      handleAutocompleteItemChange(idx, 'relationship', newValue);
                    }}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            ))
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default InputTable;