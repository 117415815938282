import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//
export default function AlertDialog({ open, setOpen, title, content, positiveButtonLabel, negativeButtonLabel, callback }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: content}}>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{ negativeButtonLabel }</Button>
          <Button onClick={callback} autoFocus>
            { positiveButtonLabel }
          </Button>
        </DialogActions>
      </Dialog>
  );
}