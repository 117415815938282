import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, ButtonGroup, Checkbox, CircularProgress, colors, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Slide, Toolbar, Typography } from '@mui/material';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/AuthContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, FontColor, FontSize, Heading, DualContentModelElementSupport, ListProperties } from 'ckeditor5';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import useDebounce from '../utils/UseDebounce';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import 'ckeditor5/ckeditor5.css';
import { toast } from 'react-toastify';

export default function Download_TOB_FormDialog({ open, setOpen, selectedPlans, handleDownloadTOB, setRecommendedPlans, recommendedPlans,
  tobNotes, setTobNotes, benefits, setBenefits, TOB_Data, fetchingTOB_Data, listBenefits, setListBenefits,
  tableOfBenefits, setTableOfBenefits, extraPlansForTOB, setExtraPlansForTOB, handleFetchTOB_Data, my_health_indonesia,
  my_health_singapore, my_health_vietnam, insurers_plans_map, providedFor, setProvidedFor,
  tobOutputType, setTobOutputType }) {
  const { auth } = useContext(AuthContext);
  const [focusInput, setFocusInput] = useState();
  const [colorUpdating, setColorUpdating] = useState();
  const [color, setColor] = useState('');

  // DeBounce Function
  useDebounce(() => {
    if (color === '') return;

    handleUpdateColor();
  }, [color], 300
  );

  useDebounce(() => {
    handleFetchTOB_Data();
  }, [extraPlansForTOB], 1200);


  useEffect(() => {
    let extra_plans = extraPlansForTOB;

    if (!my_health_indonesia) {
      const plans = ['my_health_indonesia_core', 'my_health_indonesia_essential', 'my_health_indonesia_extensive', 'my_health_indonesia_elite'];

      extra_plans = extra_plans.filter(item => !plans.includes(item));
    }

    if (!my_health_singapore) {
      const plans = ['my_health_singapore_essential', 'my_health_singapore_extensive', 'my_health_singapore_elite'];

      extra_plans = extra_plans.filter(item => !plans.includes(item));
    }

    if (!my_health_vietnam) {
      const plans = ['my_health_vietnam_essential', 'my_health_vietnam_extensive', 'my_health_vietnam_elite'];

      extra_plans = extra_plans.filter(item => !plans.includes(item));
    }

    setExtraPlansForTOB([...extra_plans]);
  }, [my_health_indonesia, my_health_singapore, my_health_vietnam]);

  useEffect(() => {
    if (selectedPlans.length > 6) {
      setTobOutputType('EXCEL');
    }
  }, [selectedPlans]);

  const planLabels = {
    'lifeline_essential': 'Lifeline Essential',
    'lifeline_classic': 'Lifeline Classic',
    'lifeline_gold': 'Lifeline Gold',
    'bwho_wmi': 'BWHO WMI',
    'bwho_wmp': 'BWHO WMI + WMP',
    'bwho_wme': 'BWHO WMI + WMP + WME',
    'bwho_ww': 'BWHO WMI + WMP + WME + WW',
    'company_essential': 'Company Essential',
    'company_classic': 'Company Classic',
    'company_gold': 'Company Gold',
    'company_classic_with_dental': 'Company Classic with Dental',
    'company_gold_with_dental': 'Company Gold with Dental',
    'company_gold_superior': 'Company Gold Superior',
    'company_gold_superior_with_dental': 'Company Gold Superior with Dental',
    'oil_gas_essential': 'Oil & Gas Essential',
    'oil_gas_classic': 'Oil & Gas Classic',
    'oil_gas_gold': 'Oil & Gas Gold',
    'first_choice_module_1': 'First Choice Module 1',
    'first_choice_module_1_2': 'First Choice Module 1 + Module 2',
    'first_choice_module_1_2_3': 'First Choice Module 1 + Module 2 + Module 3',
    'first_choice_module_1_2_3_5': 'First Choice Module 1 + Module 2 + Module 3 + Module 5',
    'first_choice_module_1_2_3_5_6': 'First Choice Module 1 + Module 2 + Module 3 + Module 5 + Module 6',
    'ihhp_hospital_plan': 'IHHP Hospital Plan',
    'ihhp_non_hospitalisation_benefits': 'IHHP HP + OP',
    'ihhp_medicine_appliances': 'IHHP HP + OP +  Med',
    'ihhp_dental_optical_a': 'IHHP HP + OP +  Med + Optical + Dental',
    'ihhp_dental_optical_b': 'IHHP HP + OP +  Med + Optical + Dental',
    'vumi_basic': 'Vumi Basic',
    'vumi_standard': 'Vumi Standard',
    'vumi_superior': 'Vumi Superior',
    'vumi_ultra': 'Vumi Ultra',
    'vumi_total': 'Vumi Total',
    'my_health_indonesia_core': 'MYHEALTH INDONESIA CORE',
    'my_health_indonesia_essential': 'MYHEALTH INDONESIA ESSENTIAL',
    'my_health_indonesia_extensive': 'MYHEALTH INDONESIA EXTENSIVE',
    'my_health_indonesia_elite': 'MYHEALTH INDONESIA ELITE',
    'my_health_singapore_essential': 'MYHEALTH SINGAPORE ESSENTIAL',
    'my_health_singapore_extensive': 'MYHEALTH SINGAPORE EXTENSIVE',
    'my_health_singapore_elite': 'MYHEALTH SINGAPORE ELITE',
    'my_health_vietnam_essential': 'MYHEALTH VIETNAM ESSENTIAL',
    'my_health_vietnam_extensive': 'MYHEALTH VIETNAM EXTENSIVE',
    'my_health_vietnam_elite': 'MYHEALTH VIETNAM ELITE',
  }

  const myHealthIndonesiaPlans = ['my_health_indonesia_core', 'my_health_indonesia_essential',
    'my_health_indonesia_extensive', 'my_health_indonesia_elite'];

  const myHealthSingaporePlans = ['my_health_singapore_essential',
    'my_health_singapore_extensive', 'my_health_singapore_elite'];

  const myHealthVietnamPlans = ['my_health_vietnam_essential',
    'my_health_vietnam_extensive', 'my_health_vietnam_elite'];




  // const [listBenefits, setListBenefits] = useState(
  //   [
  //     { index: 5, name: 'Inpatient', is_checked: true, is_parent: true },
  //     { index: 6, name: 'Accomodation Type', is_checked: true, is_parent: false },
  //     { index: 7, name: 'Organ Transplant', is_checked: true, is_parent: false },
  //     { index: 8, name: 'Cancer Treatment', is_checked: true, is_parent: false },
  //     { index: 9, name: 'Congenital Conditions', is_checked: true, is_parent: false },
  //     { index: 10, name: 'Hospice and Palliative Care', is_checked: true, is_parent: false },
  //     { index: 11, name: 'Home Nursing', is_checked: true, is_parent: false },
  //     { index: 12, name: 'Psychiatric Treatment', is_checked: true, is_parent: false },
  //     { index: 13, name: 'Parent Accomodation', is_checked: true, is_parent: false },
  //     { index: 14, name: 'Child Birth', is_checked: true, is_parent: false },
  //     { index: 15, name: 'Pregnancy Complications', is_checked: true, is_parent: false },
  //     { index: 16, name: 'Newborn Care', is_checked: true, is_parent: false },
  //     { index: 17, name: 'Local Road Ambulance', is_checked: true, is_parent: false },
  //     { index: 18, name: 'Local Air Ambulance', is_checked: true, is_parent: false },
  //     { index: 19, name: 'Outpatient', is_checked: true, is_parent: true },
  //     { index: 20, name: 'Health Check', is_checked: true, is_parent: false },
  //     { index: 21, name: 'Pathology, X-ray and Diagnostic Tests', is_checked: true, is_parent: false },
  //     { index: 22, name: 'Specialists', is_checked: true, is_parent: false },
  //     { index: 23, name: 'GP Consultations', is_checked: true, is_parent: false },
  //     { index: 24, name: 'Prescribed Medication (non-chronic illness)', is_checked: true, is_parent: false },
  //     { index: 25, name: 'Prescribed Medication (chronic illness)', is_checked: true, is_parent: false },
  //     { index: 26, name: 'Pre/Post Natal', is_checked: true, is_parent: false },
  //     { index: 27, name: 'Treatment by chiropractor, osteopath, homeopath, acupuncturist', is_checked: true, is_parent: false },
  //     { index: 28, name: 'Traditional Chinese Medicine', is_checked: true, is_parent: false },
  //     { index: 29, name: 'Dental', is_checked: true, is_parent: false },
  //     { index: 30, name: 'Optical', is_checked: true, is_parent: false },
  //     { index: 31, name: 'Further Benefits', is_checked: true, is_parent: true },
  //     { index: 32, name: 'Coverage of Pre-Existing Conditions', is_checked: true, is_parent: false },
  //     { index: 33, name: 'Chronic Illness', is_checked: true, is_parent: false },
  //     { index: 34, name: 'Kidney Dialysis', is_checked: true, is_parent: false },
  //   ]
  // );



  const handleChange = (event) => {
    if (event.target.checked) {
      setRecommendedPlans([
        ...recommendedPlans,
        event.target.name
      ])
    } else {

      const recommended_plans = recommendedPlans.filter(plan => {
        return plan !== event.target.name;
      });

      setRecommendedPlans(recommended_plans);
    }
  };

  const handleChangeExtraPlans = (event) => {
    if (event.target.checked) {
      setExtraPlansForTOB([
        ...extraPlansForTOB,
        event.target.name
      ])
    } else {

      const extra_plans_for_tob = extraPlansForTOB.filter(plan => {
        return plan !== event.target.name;
      });

      setExtraPlansForTOB(extra_plans_for_tob);
    }
  };

  function handleParentBenefitsCheckboxChange(event) {
    const name = event.target.name;
    const is_checked = event.target.checked;

    benefits[name].is_checked = is_checked;

    benefits[name].items.forEach(benefit => {
      benefit.is_checked = is_checked;
    });

    console.log(benefits);

    setBenefits({ ...benefits });
  }

  function handleBenefitsCheckboxChange(event) {
    const name = event.target.name;
    const is_checked = event.target.checked;

    for (const category in benefits) {
      benefits[category].items.forEach(benefit => {
        if (benefit.name === name) {
          benefit.is_checked = is_checked;
        }
      });

      const anyChecked = benefits[category].items.some(benefit => benefit.is_checked);
      const noneChecked = benefits[category].items.every(benefit => !benefit.is_checked);

      if (anyChecked) {
        benefits[category].is_checked = true;
      } else if (noneChecked) {
        benefits[category].is_checked = false;
      }
    }

    setBenefits({ ...benefits });
  }

  function isIndeterminate(parentKey) {
    const allChecked = benefits[parentKey].items.every(benefit => benefit.is_checked);
    const noneChecked = benefits[parentKey].items.every(benefit => !benefit.is_checked);
    return !(allChecked || noneChecked);
  }


  const handleClose = () => {
    setOpen(false);
  };

  function getTitle() {
    return 'Download TOB';
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  function findBenefitByIndex(index) {
    for (let i = 0; i < listBenefits.length; i++) {
      if (listBenefits[i].index == index) {
        return listBenefits[i];
      }
    }
    return null;
  }


  function handleBenefitCheckChange(index, is_checked, is_parent) {

    for (let i = 0; i < listBenefits.length; i++) {
      if (listBenefits[i].index == index) {
        listBenefits[i].is_checked = is_checked;

        if (is_parent) {
          for (let j = i + 1; j < listBenefits.length; j++) {
            if (listBenefits[j].is_parent) {
              break;
            }

            listBenefits[j].is_checked = is_checked;
          }
        }

      }
    }

    setListBenefits([...listBenefits]);
  }

  function updateTableOfBenefits() {
    const rowsKey = Object.keys(tableOfBenefits);
    for (let i = 0; i < rowsKey.length; i++) {
      const colsKey = Object.keys(tableOfBenefits[rowsKey[i]]);

      for (let j = 0; j < colsKey.length; j++) {
        const inputId = 'input_' + rowsKey[i] + '_' + colsKey[j];

        const input = document.getElementById(inputId);

        const value = input.value;

        tableOfBenefits[rowsKey[i]][colsKey[j]].cell_value = value;
      }
    }

    setTableOfBenefits({ ...tableOfBenefits });
  }

  function updateInputValues() {
    const tobNotesTextArea = document.getElementById('tob_notes');
    setTobNotes(tobNotesTextArea.value);
  }

  // const checkIfParentIndeterminate = (index) => {
  //   const parent = listBenefits.find(item => item.index == index && item.is_parent);
  //   if (!parent) {
  //     return false;
  //   }

  //   const childItems = listBenefits.filter(item => !item.is_parent && item.index > parent.index && (item.index <= parent.index + 10));

  //   const allChecked = childItems.every(item => item.is_checked);
  //   const noneChecked = childItems.every(item => !item.is_checked);

  //   return !(allChecked || noneChecked);
  // };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTobNotes(data);
  };

  function handleFormat(format) {
    const current = tableOfBenefits[focusInput.rowKey][focusInput.colKey].font_weight;
    let updateVal = '';
    let updateKey = '';

    if (format == 'b') {
      updateKey = 'font_weight';

      if (current == '600') {
        updateVal = '400';
      } else {
        updateVal = '600';
      }
    }

    if (format == 'center') {
      updateKey = 'horizontal_alignment';
      updateVal = 'center';
    }

    if (format == 'left') {
      updateKey = 'horizontal_alignment';
      updateVal = 'left';
    }

    if (format == 'right') {
      updateKey = 'horizontal_alignment';
      updateVal = 'right';
    }

    tableOfBenefits[focusInput.rowKey][focusInput.colKey][updateKey] = updateVal;
    setTableOfBenefits({ ...tableOfBenefits });

    document.getElementById('input_' + focusInput.rowKey + '_' + focusInput.colKey).focus();
    // document.getElementById('color').click();
  }

  function showColorPicker(type) {
    const el = document.getElementById('color-picker');

    console.log(tableOfBenefits[focusInput.rowKey][focusInput.colKey][type], type);

    el.value = tableOfBenefits[focusInput.rowKey][focusInput.colKey][type];
    el.click();
  }

  function handleUpdateColor() {

    tableOfBenefits[focusInput.rowKey][focusInput.colKey][colorUpdating] = color;
    setTableOfBenefits({ ...tableOfBenefits });
  }

  function filteredSelectedPlans() {
    const plansToRemove = ['my_health_indonesia', 'my_health_singapore', 'my_health_vietnam'];

    // Remove plans
    plansToRemove.forEach(plan => {
      const index = selectedPlans.indexOf(plan);
      if (index > -1) {
        selectedPlans.splice(index, 1);
      }
    });


    // Add plans
    extraPlansForTOB.forEach(plan => {
      if (!selectedPlans.includes(plan)) {
        selectedPlans.push(plan);
      }
    });



    return selectedPlans;
  }




  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose}>

        {/* <DialogTitle>{getTitle()}</DialogTitle> */}
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Download Table Of Benefits
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box minWidth={550}>
            <Typography sx={{ color: '#1976d2', fontSize: 20 }}>Provided For</Typography>
            <TextField
              // value={providedFor}
              // onChange={(event) => {
              //   setProvidedFor(event.target.value);
              // }}
              autoFocus
              variant='outlined'
              id="provided-for-input"
              size='small'
              placeholder='Client Name'
              sx={{ mb: 2, mt: 1, width: 275 }}
              defaultValue={providedFor}

            />

            {/* <Box sx={{ background: colors.yellow[400], padding: 2 }}> */}
            {
              my_health_indonesia &&
              <Box sx={{ backgroundColor: '#e0e0e0', mb: 2 }}>
                <Typography sx={{ mt: 2, color: '#ff9800', fontSize: 20 }}>Select MyHEALTH Indonesia Plans</Typography>
                <FormControl component="fieldset" variant="standard">
                  <Box sx={{ display: 'flex' }}>
                    {
                      myHealthIndonesiaPlans.map(plan => (
                        <FormControlLabel
                          key={plan}
                          control={
                            <Checkbox checked={extraPlansForTOB.indexOf(plan) !== -1} onChange={(e) => {
                              handleChangeExtraPlans(e);
                            }} name={plan} />
                          }
                          label={insurers_plans_map[plan].insurer_label + ' ' + insurers_plans_map[plan].plan_label}
                        />
                      ))
                    }

                  </Box>
                </FormControl>
              </Box>
            }

            {
              my_health_singapore &&
              <Box sx={{ backgroundColor: '#e0e0e0', mb: 2 }}>
                <Typography sx={{ mt: 2, color: '#ff9800', fontSize: 20 }}>Select MyHEALTH Singapore Plans</Typography>
                <FormControl component="fieldset" variant="standard">
                  <Box sx={{ display: 'flex' }}>
                    {
                      myHealthSingaporePlans.map(plan => (
                        <FormControlLabel
                          key={plan}
                          control={
                            <Checkbox checked={extraPlansForTOB.indexOf(plan) !== -1} onChange={(e) => {
                              handleChangeExtraPlans(e);
                            }} name={plan} />
                          }
                          label={insurers_plans_map[plan].insurer_label + ' ' + insurers_plans_map[plan].plan_label}
                        />
                      ))
                    }

                  </Box>
                </FormControl>
              </Box>
            }

            {
              my_health_vietnam &&
              <Box sx={{ backgroundColor: '#e0e0e0', mb: 2 }}>
                <Typography sx={{ mt: 2, color: '#ff9800', fontSize: 20 }}>Select MyHEALTH Vietnam Plans</Typography>
                <FormControl component="fieldset" variant="standard">
                  <Box sx={{ display: 'flex' }}>
                    {
                      myHealthVietnamPlans.map(plan => (
                        <FormControlLabel
                          key={plan}
                          control={
                            <Checkbox checked={extraPlansForTOB.indexOf(plan) !== -1} onChange={(e) => {
                              handleChangeExtraPlans(e);
                            }} name={plan} />
                          }
                          label={insurers_plans_map[plan].insurer_label + ' ' + insurers_plans_map[plan].plan_label}
                        />
                      ))
                    }

                  </Box>
                </FormControl>
              </Box>
            }
            {/* </Box> */}

            <Typography sx={{ color: '#1976d2', fontSize: 20 }}>Select Recommended Plans</Typography>
            <FormControl component="fieldset" variant="standard">
              {/* <FormLabel component="legend">Recommended Plan</FormLabel> */}
              <Box sx={{ display: 'flex' }}>
                {
                  filteredSelectedPlans().map(plan => (
                    <FormControlLabel
                      key={plan}
                      control={
                        <Checkbox checked={recommendedPlans.indexOf(plan) !== -1} onChange={handleChange} name={plan} />
                      }
                      label={insurers_plans_map[plan].insurer_label + ' ' + insurers_plans_map[plan].plan_label}
                    />
                  ))
                }

              </Box>
            </FormControl>
            <Typography sx={{ fontSize: 20, color: '#1976d2', mt: 2 }}>Table of Benefits</Typography>
            <Accordion sx={{ mt: 1 }} variant='outlined'>
              <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                <Typography sx={{ fontSize: 16 }}>Show / Hide Table of Benefits</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  fetchingTOB_Data ?

                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                    :
                    (
                      tableOfBenefits &&
                      <Box>
                        <ButtonGroup sx={{ mb: 3 }}>
                          <Button onClick={(e) => {
                            handleFormat('b')
                          }}><FormatBoldIcon /></Button>
                          <Button onClick={(e) => {
                            handleFormat('left')
                          }}><FormatAlignLeftIcon /></Button>
                          <Button onClick={(e) => {
                            handleFormat('center')
                          }}><FormatAlignCenterIcon /></Button>
                          <Button onClick={(e) => {
                            handleFormat('right')
                          }}><FormatAlignRightIcon /></Button>
                          <Button onClick={() => {
                            setColorUpdating('cell_text_color');

                            showColorPicker('cell_text_color');


                          }}><FormatColorTextIcon /></Button>
                          <Button onClick={() => {
                            setColorUpdating('cell_bg_color');

                            showColorPicker('cell_bg_color');
                          }}><FormatColorFillIcon /></Button>
                        </ButtonGroup>
                        <input id="color-picker" type="color" onChange={(e) => {
                          setColor(e.target.value);
                        }} style={{ visibility: 'hidden' }} />

                        <table style={{ borderSpacing: 0, borderCollapse: 'collapse' }}>
                          <tbody>
                            {
                              Object.keys(tableOfBenefits).map(rowKey => {
                                const row = tableOfBenefits[rowKey];
                                const benefit = findBenefitByIndex(rowKey);
                                return (
                                  <tr key={rowKey}>
                                    {benefit !== null ?
                                      <td style={{ paddingRight: '10px', width: '40px', display: 'flex', justifyContent: benefit.is_parent ? 'start' : 'end' }}>
                                        <input
                                          type='checkbox'
                                          style={{ transform: 'scale(1.5)' }}
                                          checked={benefit.is_checked}
                                          onChange={(e) => {
                                            handleBenefitCheckChange(rowKey, e.target.checked, benefit.is_parent);
                                          }}
                                        />
                                      </td>
                                      : <td></td>
                                    }
                                    {
                                      Object.keys(row).map(colKey => {
                                        const cell = row[colKey];
                                        return (
                                          <td key={colKey} style={{ padding: 0, margin: 0, border: '1px solid #000000' }}>
                                            <input
                                              id={'input_' + rowKey + '_' + colKey}
                                              readOnly={benefit != null ? !benefit.is_checked : false}
                                              type="text" defaultValue={cell.cell_value}
                                              style={{ border: 0, backgroundColor: cell.cell_bg_color, color: cell.cell_text_color, textAlign: cell.horizontal_alignment, fontWeight: cell.font_weight, height: '30px', width: '100%', minWidth: cell.cell_value ? cell.cell_value.length + 'ch' : null }}
                                              onFocus={() => { setFocusInput({ rowKey, colKey }) }}
                                            />
                                          </td>
                                        );
                                      })
                                    }
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                      </Box>
                      // <table>
                      //   <tbody>
                      //     {
                      //       TOB_Data.table.map(row => {
                      //         return <tr>
                      //           {
                      //             row.map(col => {
                      //               return <td>{ col.cell_value }</td>
                      //             })
                      //           }
                      //         </tr>
                      //       })
                      //     }
                      //   </tbody>
                      // </table>
                    )
                }
              </AccordionDetails>
            </Accordion>






            {/* <Typography sx={{ mt: 2, color: '#595959', fontSize: 18 }}>Show/Hide Benefits</Typography>

            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                Inpatient Benefits
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  label="Inpatient"
                  control={
                    <Checkbox indeterminate={isIndeterminate('inpatient')} checked={benefits.inpatient.is_checked} name='inpatient' onChange={handleParentBenefitsCheckboxChange} />
                  }
                />
                <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                  {
                    benefits.inpatient.items.map(benefit => {
                      return <FormControlLabel
                        key={benefit.name}
                        label={benefit.name}
                        control={
                          <Checkbox checked={benefit.is_checked} name={benefit.name} onChange={handleBenefitsCheckboxChange} />
                        }
                      />
                    })
                  }
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                Outpatient Benefits
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  label="Outpatient"
                  control={
                    <Checkbox indeterminate={isIndeterminate('outpatient')} checked={benefits.outpatient.is_checked} name='outpatient' onChange={handleParentBenefitsCheckboxChange} />
                  }
                />
                <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                  {
                    benefits.outpatient.items.map(benefit => {
                      return <FormControlLabel
                        key={benefit.name}
                        label={benefit.name}
                        control={
                          <Checkbox checked={benefit.is_checked} name={benefit.name} onChange={handleBenefitsCheckboxChange} />
                        }
                      />
                    })
                  }
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion sx={{ mt: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                Further Benefits
              </AccordionSummary>
              <AccordionDetails>
                <FormControlLabel
                  label="Further Benefits"
                  control={
                    <Checkbox indeterminate={isIndeterminate('further_benefits')} checked={benefits.further_benefits.is_checked} name='further_benefits' onChange={handleParentBenefitsCheckboxChange} />
                  }
                />
                <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
                  {
                    benefits.further_benefits.items.map(benefit => {
                      return <FormControlLabel
                        key={benefit.name}
                        label={benefit.name}
                        control={
                          <Checkbox checked={benefit.is_checked} name={benefit.name} onChange={handleBenefitsCheckboxChange} />
                        }
                      />
                    })
                  }
                </Box>
              </AccordionDetails>
            </Accordion> */}
            <Typography sx={{ mt: 3, mb: 1, color: '#1976d2', fontSize: 20 }}>Output Type</Typography>
            <FormControl size='small' sx={{ width: 275 }}>
              <Select
                id="output-type-select"
                value={tobOutputType}
                onChange={(e) => {
                  setTobNotes('');
                  setTobOutputType(e.target.value);
                }}
                readOnly={selectedPlans.length > 6}
              >
                <MenuItem value="PDF">PDF</MenuItem>
                <MenuItem value="EXCEL">EXCEL</MenuItem>
              </Select>
            </FormControl>

            <Typography sx={{ mt: 3, mb: 1, color: '#1976d2', fontSize: 20 }}>Notes</Typography>

            {
              tobOutputType === 'EXCEL' &&
              <TextField
                defaultValue={tobNotes}
                // value={tobNotes}
                // onChange={(event) => {
                //   setTobNotes(event.target.value);
                // }}
                id='tob_notes'
                margin="dense"
                label="Notes"
                fullWidth
                multiline={true}
                rows={5}
                sx={{ mt: 2 }}
              />
            }


            {
              tobOutputType === 'PDF' &&
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: [
                      'undo', 'redo', '|', 'heading', '|',
                      'bold', 'italic', 'fontColor', '|',
                      'numberedList', 'bulletedList'
                    ],
                  },
                  plugins: [
                    Bold, Essentials, Italic, Mention, Paragraph,
                    Undo, FontColor, Heading, ListProperties
                  ],
                  fontColor: {
                    colors: [{
                      color: '#f44336',
                      label: 'Red'
                    },
                    {
                      color: '#2196f3',
                      label: 'Blue'
                    },
                    {
                      color: '#4caf50',
                      label: 'Green'
                    },
                    {
                      color: '#ff9800',
                      label: 'Orange'
                    },
                    {
                      color: '#ffeb3b',
                      label: 'Yellow'
                    },
                    {
                      color: '#9e9e9e',
                      label: 'Grey'
                    },
                    {
                      color: '#795548',
                      label: 'Brown'
                    },
                    ]
                  },
                }}
                data={tobNotes}
                onBlur={handleEditorChange}
              />
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            // updateInputValues();
            if (document.getElementById('provided-for-input').value.trim() === '') {
              toast.error('Enter Provided For', {
                autoClose: 1000,
                hideProgressBar: true,
              });

              return;
            }


            setProvidedFor(document.getElementById('provided-for-input').value);

            if (tobOutputType === 'EXCEL') {
              setTobNotes(document.getElementById('tob_notes').value);
            }


            setTimeout(() => {
              updateTableOfBenefits();
              console.log(tobNotes);
            }, 300);



            setTimeout(() => {
              if (tobOutputType === 'PDF') {
                handleDownloadTOB(document.getElementById('provided-for-input').value, tobNotes);
              } else if (tobOutputType === 'EXCEL') {
                handleDownloadTOB(document.getElementById('provided-for-input').value, document.getElementById('tob_notes').value);
              }
              
              handleClose();
            }, 700);

          }}>Download</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}