import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import { Button, Checkbox, colors, Container, FormControlLabel, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import zeroDeductibleUniform from '../utils/zeroDeductibleUniform';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PremiumsSummaryDialog({ open, setOpen, clients, response, selectedPlans, currency, deductibles,
  discounts, insurers_plans_map, computedRates, paymentFrequency,
  specificAreaOfCoverage }) {

  const [showImageCopy, setShowImageCopy] = useState(false);
  const [showDeductible, setShowDeductible] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  function handleCloseSnackbar() {
    setShowImageCopy(false);
  }


  function getRate(selectedPlan, index) {
    const insurer = insurers_plans_map[selectedPlan].insurer;
    const plan = selectedPlan.replace(insurers_plans_map[selectedPlan].insurer + '_', '');

    // if (!response || !response[insurer] || !response[insurer].rates[index] || !response[insurer].rates[index][plan]) {
    //   return '';
    // }

    // const totalPercent = (100 - discounts[insurer]) * 0.01;

    // return response[insurer].rates[index][plan] * totalPercent;

    if (!computedRates || !computedRates[insurer] || !computedRates[insurer][index] || !computedRates[insurer][index][plan]) {
      return '';
    }

    return computedRates[insurer][index][plan];
  }

  function getCurrency(selectedPlan) {
    const insurer = insurers_plans_map[selectedPlan].insurer;

    if (!computedRates || !computedRates[insurer] || !computedRates[insurer][0] || !computedRates[insurer][0].currency) {
      return '';
    }

    // if (computedRates[insurer].length === 0) {
    //   return '';
    // }


    return computedRates[insurer][0].currency;
  }

  function getComputedRate(selectedPlan, index) {
    const insurer = insurers_plans_map[selectedPlan].insurer;
    const plan = selectedPlan.replace(insurers_plans_map[selectedPlan].insurer + '_', '');

    // if (!response || !response[insurer] || !response[insurer].rates[index] || !response[insurer].rates[index][plan]) {
    //   return '';
    // }

    // const totalPercent = (100 - discounts[insurer]) * 0.01;

    // return response[insurer].rates[index][plan] * totalPercent;

    if (!computedRates || !computedRates[insurer] || !computedRates[insurer][index] || !computedRates[insurer][index][plan]) {
      return '';
    }

    return computedRates[insurer][index];
  }

  // function getSubtotalRate(selectedPlan) {

  //   const insurer = insurers_plans_map[selectedPlan].insurer;
  //   const plan = selectedPlan.replace(insurers_plans_map[selectedPlan].insurer + '_', '');

  //   if (!response || !response[insurer]) {
  //     return '';
  //   }

  //   let total = 0;
  //   const rates = response[insurer].rates;

  //   for (let i = 0; i < rates.length; i++) {
  //     total += rates[i][plan];
  //   }

  //   const totalPercent = (100 - discounts[insurer]) * 0.01;

  //   return total * totalPercent;
  // }

  function getSubtotalRate(selectedPlan) {

    const insurer = insurers_plans_map[selectedPlan].insurer;
    const plan = selectedPlan.replace(insurers_plans_map[selectedPlan].insurer + '_', '');

    if (!computedRates || !computedRates[insurer]) {
      return '';
    }

    let total = 0;
    const clientRate = computedRates[insurer];

    for (let i = 0; i < clientRate.length; i++) {
      total += clientRate[i][plan];
    }

    return total;
  }

  function getDeductible(selectedPlan) {
    const insurer = insurers_plans_map[selectedPlan].insurer;
    const plan = selectedPlan.replace(insurers_plans_map[selectedPlan].insurer + '_', '');


    if (insurer === 'lifeline') {
      return deductibles['lifeline'];
    } else if (insurer === 'bwho') {
      const wmi_deductible = deductibles['bwho']['wmi_deductible'];
      const wmp_deductible = deductibles['bwho']['wmp_deductible'];
      const wme_deductible = deductibles['bwho']['wme_deductible'];
      const ww_deductible = deductibles['bwho']['ww_deductible'];

      if (plan === 'wmi') {
        return wmi_deductible;
      } else if (plan === 'wmp') {
        if (wmi_deductible === wmp_deductible) {
          return wmi_deductible;
        }

        return wmi_deductible + '; ' + wmp_deductible;
      } else if (plan === 'wme') {
        if (wmi_deductible === wmp_deductible && wmp_deductible === wme_deductible) {
          return wmi_deductible;
        }

        return wmi_deductible + '; ' + wmp_deductible + '; ' + wme_deductible;
      } else if (plan === 'ww') {
        if (wmi_deductible === wmp_deductible && wmp_deductible === wme_deductible && wme_deductible === ww_deductible) {
          return wmi_deductible;
        }

        return wmi_deductible + '; ' + wmp_deductible + '; ' + wme_deductible + '; ' + ww_deductible;
      }

      // return deductibles['bwho'][plan + '_deductible'];
    } else if (insurer === 'company') {
      return deductibles['company']['label'];
    } else if (insurer === 'first_choice') {
      return deductibles['first_choice'];
    } else if (insurer === 'ihhp') {
      return deductibles['ihhp'];
    } else if (insurer === 'vumi') {
      return deductibles['vumi'];
    } else if (insurer === 'my_health_indonesia') {
      return deductibles['my_health_indonesia'] + ' Deductible';
    } else if (insurer === 'my_health_singapore') {
      return deductibles['my_health_singapore'] + ' Deductible';
    } else if (insurer === 'my_health_vietnam') {
      return deductibles['my_health_vietnam'] + ' Deductible';
    } else if (insurer === 'optimum_global') {
      return deductibles['optimum_global'];
    }


    return 'N/A';
  }

  function formatRate(selectedPlan, currency, rate) {
    if (isNaN(rate) || rate == 'Unavailable' || typeof rate === 'string') {
      return <span style={{ color: '#f44336' }}>{'Unavailable'}</span>
    }

    if (isNaN(rate)) {
      return rate;
    }

    let _currency = currency;

    if (selectedPlan === 'my_health_indonesia') {
      _currency = 'USD';
    }

    if (selectedPlan === 'my_health_singapore') {
      _currency = 'SGD';
    }

    if (selectedPlan === 'my_health_vietnam') {
      _currency = 'USD';
    }



    return _currency + ' ' + rate.toLocaleString('en-PH', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function sortedPlans() {
    const plans = selectedPlans;

    // Create an array of the keys in insurers_plans_map to use as the order reference
    const order = Object.keys(insurers_plans_map);

    // Sort the plans based on their index in the order array
    return plans.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  }

  // function getCurrencySign() {
  //   if (currency === 'USD') {
  //     return '$';
  //   } else if (currency === 'EURO') {
  //     return '€';
  //   } else if (currency === 'GBP') {
  //     return '£';
  //   } else if (currency === 'SGD') {
  //     return 'SGD';
  //   }

  //   return '';
  // }

  function getPlanLevelRowSpan() {
    let rowSpan = 3;

    if (showDeductible) {
      rowSpan += 1;
    }

    return rowSpan;
  }

  function printPlansLabel() {
    return sortedPlans().map(selectedPlan => {
      if (selectedPlan === 'my_health_indonesia') {
        if (!computedRates['my_health_indonesia']) return '';

        if (computedRates['my_health_indonesia'].length === 0) return '';

        return <td>{computedRates['my_health_indonesia'][0]['plan_label']}</td>;
      }

      if (selectedPlan === 'my_health_singapore') {
        if (!computedRates['my_health_singapore']) return '';

        if (computedRates['my_health_singapore'].length === 0) return '';

        return <td>{computedRates['my_health_singapore'][0]['plan_label']}</td>;
      }

      if (selectedPlan === 'my_health_vietnam') {
        if (!computedRates['my_health_vietnam']) return '';

        if (computedRates['my_health_vietnam'].length === 0) return '';

        return <td>{computedRates['my_health_vietnam'][0]['plan_label']}</td>;
      }

      // if (selectedPlan === 'morgan_price_core_ip') {
      //   if (!computedRates['morgan_price']) return '';

      //   if (computedRates['morgan_price'].length === 0) return '';

      //   return <td>{computedRates['morgan_price'][0]['plan_label']}</td>;
      // }


      return <td>{insurers_plans_map[selectedPlan].plan_label}</td>
    })
  }

  function printPlansLabelMUI() {
    return sortedPlans().map(selectedPlan => {
      if (selectedPlan === 'my_health_indonesia') {
        if (!computedRates['my_health_indonesia']) return '';

        if (computedRates['my_health_indonesia'].length === 0) return '';

        return <TableCell sx={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>{computedRates['my_health_indonesia'][0]['plan_label']}</TableCell>;
      }

      if (selectedPlan === 'my_health_singapore') {
        if (!computedRates['my_health_singapore']) return '';

        if (computedRates['my_health_singapore'].length === 0) return '';

        return <TableCell sx={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>{computedRates['my_health_singapore'][0]['plan_label']}</TableCell>;
      }

      if (selectedPlan === 'my_health_vietnam') {
        if (!computedRates['my_health_vietnam']) return '';

        if (computedRates['my_health_vietnam'].length === 0) return '';

        return <TableCell sx={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>{computedRates['my_health_vietnam'][0]['plan_label']}</TableCell>;
      }


      return <TableCell sx={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>{insurers_plans_map[selectedPlan].plan_label.toUpperCase()}</TableCell>
    })
  }

  function paymentFrequencyLabel() {
    const paymentFrequencyLabelMap = {
      'Annually': 'Annual',
      'Semi Annually': 'Semi Annual',
      'Quarterly': 'paymentFrequency',
      'Monthly': 'Monthly',
    };

    return paymentFrequencyLabelMap[paymentFrequency];
  }

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Plan Comparison
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth={selectedPlans.length <= 3 ? 'lg' : 'xl'}>
          {/* <Typography sx={{ fontSize: 20, color: '#1976d2', mt: 3 }}>Options</Typography>
          <FormControlLabel control={<Checkbox checked={showDeductible} onChange={(e) => {
            setShowDeductible(e.target.checked);
          }} />} label="Show Deductible" /> */}
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '75px' }}>

            <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
              <Button
                sx={{ mb: 1 }}
                onClick={() => {
                  var node = document.getElementById('table-premiums-summary');

                  domtoimage.toBlob(node).then(function (blob) {
                    // Create ClipboardItem with blob and its type, and add to an array
                    const data = [new ClipboardItem({ [blob.type]: blob })];
                    // Write the data to the clipboard
                    navigator.clipboard.write(data);

                    setShowImageCopy(true);
                  })
                    .catch(function (error) {
                      console.error('oops, something went wrong!', error);
                    });
                }}
                endIcon={<ContentCopyIcon />}
              >Copy Image</Button>
            </div>
          </div>


          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <table id="table-premiums-summary" className='table-premiums-summary' style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse', }}>
              <tr style={{ background: '#050A30', color: '#ebe6e6' }}>
                <td colSpan={sortedPlans().length + 1} style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 15 }}>PREMIUM COMPARISON</td>
              </tr>
              <tr style={{ background: '#050A30', color: '#ebe6e6', textAlign: 'center' }}>
                <td rowSpan={getPlanLevelRowSpan()}>PLAN LEVEL</td>
                {
                  sortedPlans().map(selectedPlan => {
                    return <td>{insurers_plans_map[selectedPlan].insurer_label}</td>
                  })
                }
              </tr>
              {
                <tr style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>
                  {
                    sortedPlans().map(selectedPlan => {
                      const insurer = insurers_plans_map[selectedPlan].insurer;

                      const area_of_coverage = specificAreaOfCoverage[insurer];

                      return <td style={{ fontSize: area_of_coverage.length > 20 ? 12 : null }}>{area_of_coverage}</td>
                    })
                  }
                </tr>
              }
              {
                showDeductible &&
                <tr style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>
                  {
                    sortedPlans().map(selectedPlan => {
                      let deductible = getDeductible(selectedPlan);

                      deductible = zeroDeductibleUniform(deductible);

                      return <td style={{ fontSize: deductible.length > 20 ? 12 : null }}>{deductible}</td>
                    })
                  }
                </tr>
              }
              <tr style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>
                {/* {
                  sortedPlans().map(selectedPlan => {
                    return <td>{insurers_plans_map[selectedPlan].plan_label.toUpperCase()}</td>
                  })
                } */}
                {
                  printPlansLabel()
                }
              </tr>
              {
                clients.map((client, index) => {
                  return <tr style={{ background: '#EDEDED', color: '#050A30', textAlign: 'center' }}>
                    <td>{client.full_name} ({client.age}) {client.country_of_residence}</td>

                    {
                      sortedPlans().map(selectedPlan => {
                        return <td>{formatRate(selectedPlan, getCurrency(selectedPlan), getRate(selectedPlan, index))}</td>
                      })
                    }

                  </tr>
                })

              }
              <tr style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>
                <td>Total Indicative {paymentFrequencyLabel()} Premium</td>
                {
                  sortedPlans().map(selectedPlan => {
                    return <td>{formatRate(selectedPlan, getCurrency(selectedPlan), getSubtotalRate(selectedPlan))}</td>
                  })
                }
              </tr>
            </table>
          </div>
          {/* <TableContainer id="table-premiums-summary"  component={Paper}>
            <Table size='small' sx={{ minWidth: 650, width: '100%', tableLayout: 'fixed' }} aria-label="simple table">
              <TableHead sx={{ background: '#050A30' }}>
                <TableRow>
                  <TableCell rowSpan={3} sx={{ color: '#ebe6e6', textAlign: 'center' }}>PLAN LEVEL</TableCell>
                  {
                    sortedPlans().map(selectedPlan => {
                      return <TableCell sx={{ color: '#ebe6e6', textAlign: 'center' }}>{insurers_plans_map[selectedPlan].insurer_label}</TableCell>
                    })
                  }
                </TableRow>
                <TableRow>
                  {
                    sortedPlans().map(selectedPlan => {
                      let deductible = getDeductible(selectedPlan);

                      deductible = zeroDeductibleUniform(deductible);

                      return <TableCell sx={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }} style={{ fontSize: deductible.length > 20 ? 12 : null }}>{deductible}</TableCell>
                    })
                  }
                </TableRow>
                <TableRow>
                  {
                    printPlansLabelMUI()
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  clients.map((client, index) => {
                    return <TableRow>
                      <TableCell sx={{ background: '#EDEDED', color: '#050A30', textAlign: 'center' }}>{client.full_name.toUpperCase()} ({client.age}) {client.country_of_residence.toUpperCase()}</TableCell>

                      {
                        sortedPlans().map(selectedPlan => {
                          return <TableCell sx={{ background: '#EDEDED', color: '#050A30', textAlign: 'center' }}>{formatRate(selectedPlan, currency, getRate(selectedPlan, index))}</TableCell>
                        })
                      }

                    </TableRow>
                  })

                }

                <TableRow>
                  <TableCell style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>TOTAL INDICATIVE ANNUAL PREMIUM</TableCell>
                  {
                    sortedPlans().map(selectedPlan => {
                      return <TableCell style={{ background: '#737373', color: '#ebe6e6', textAlign: 'center' }}>{formatRate(selectedPlan, currency, getSubtotalRate(selectedPlan))}</TableCell>
                    })
                  }
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </Container>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showImageCopy}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Image Copied to Clipboard"
      />
    </Fragment>
  );
}