import { Box, Checkbox, colors, FormControlLabel, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../utils/AuthContext';
import { updateRates, uploadNewRates } from '../services/InsurerRateService';

export default function RatesFormDialog({ open, setOpen, selectedId, selectedTitle,
  setIsFileUploading, handleFetchInsurerRates, mode, insurerRate }) {
  const { auth, setAuth } = useContext(AuthContext);

  const [file, setFile] = useState(null);

  const [version, setVersion] = useState('');
  const [isLatest, setLatest] = useState(false);

  useEffect(() => {
    if (mode === 'update') {
      setVersion(insurerRate.version);
      setLatest(insurerRate.is_latest);
    }
    
  }, [mode, insurerRate]);

  function handleUploadFile() {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('insurer', selectedId);
    formData.append('version', version);

    setOpen(false);
    setIsFileUploading(true);

    const toastId = toast.loading("Uploading...");

    uploadNewRates({ body: formData, token: auth.token }).then(() => {
      toast.update(toastId, {
        type: 'success',
        render: selectedTitle + ' Uploaded',
        // autoClose: 2500,
        closeButton: true,
        hideProgressBar: true,
        isLoading: false
      });

      setIsFileUploading(false);

      handleFetchInsurerRates();
    })
      .catch(err => {
        setIsFileUploading(false);
      });
  }

  function handleUpdate() {
    const formData = new FormData();
    formData.append('version', version);
    formData.append('is_latest', isLatest);

    setOpen(false);
    setIsFileUploading(true);

    const toastId = toast.loading("Updating...");

    updateRates({id: insurerRate.id, body: formData, token: auth.token }).then(() => {
      toast.update(toastId, {
        type: 'success',
        render: selectedTitle + ' Updated',
        // autoClose: 2500,
        closeButton: true,
        hideProgressBar: true,
        isLoading: false
      });

      setIsFileUploading(false);

      handleFetchInsurerRates();
    })
      .catch(err => {
        setIsFileUploading(false);
      });
  }

  function handleSubmit() {
    if (mode === 'add') {
      handleUploadFile();
    } else if (mode === 'update') {
      handleUpdate();
    }
    
  }

  const handleClose = () => {
    setOpen(false);
  };

  function getTitle() {
    if (mode === 'add') {
      return 'Upload New Rates';
    } else if (mode === 'update') {
      return 'Update Rates';
    }
  }

  function getPositiveButtonLabel() {
    if (mode === 'add') {
      return 'Upload';
    } else if (mode === 'update') {
      return 'Submit';
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">
        {getTitle()}
      </DialogTitle>
      <DialogContent>
        <Box minWidth={500}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ mr: 1, fontSize: 16 }}>
              Type:
            </Typography>
            <Typography sx={{ fontSize: 16, color: colors.blue[700], fontWeight: 600 }}>{selectedTitle}</Typography>
          </Box>
          {
            mode === 'add' && <Box sx={{ mt: 2 }}>
              <input type='file' onChange={(e) => {
                setFile(e.target.files[0]);
              }} />
            </Box>
          }

          <Box sx={{ mt: 2, }}>
            <TextField value={version} onChange={(e) => { setVersion(e.target.value) }} size='small' label='Version Name' fullWidth />
          </Box>
          {
            mode === 'update' &&
            <Box sx={{ mt: 1 }}>
              <FormControlLabel control={<Checkbox checked={isLatest} onChange={(e) => {
                setLatest(e.target.checked);
              }} />} label="Latest Version" />
            </Box>
          }

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button autoFocus onClick={handleSubmit}>
          {getPositiveButtonLabel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
}