import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Chip, CircularProgress, colors, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function AppFileCard({ sx, id, title, fileId, setFileId, setFile, uploadButtonTitle, downloadButtonTitle, isUploading,
  setShowUploadDialog, setOpenRatesFormDialog, getLastUpdate, downloadFile, fetchingFiles, setFileDescription,
  fileType = 'excel', setSelectedId, setSelectedTitle, getRatesLatestVersion, getAllRatesByInsurer, insurerRates,
  setRatesFormMode, setInsurerRate, setOpenDeleteDialog, setInsurerRateToDelete }) {

  function getInputAccept() {
    if (fileType === 'excel') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    } else if (fileType === 'text') {
      return '.txt'
    } else if (fileType === 'json') {
      return '.json'
    }
  }

  const [rates, setRates] = useState([]);

  useEffect(() => {
    setRates(getAllRatesByInsurer(id));
  }, [insurerRates]);

  return (
    <Card variant="outlined" sx={{ p: 2, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>

        <Box sx={{ display: 'flex', alignItems: 'start' }}>
          {/* <Button onClick={() => {
          setFile(null);
          setFileDescription(title);
        }} size='small' component="label" variant="contained" startIcon={<CloudUploadIcon />}>
          {uploadButtonTitle}
          {
            (isUploading === true && fileId === id) &&
            <CircularProgress size='18px' sx={{ color: '#fff', ml: 1 }} />
          }

          <VisuallyHiddenInput type="file" accept={getInputAccept()} onChange={(e) => {
            setFile(e.target.files[0]);
            setFileId(id);
            setShowUploadDialog(false);

            e.target.value = null;
          }}
          />
        </Button> */}
          <Button sx={{ ml: 1 }} onClick={() => {
            setSelectedId(id);
            setSelectedTitle(title);
            setRatesFormMode('add');
            setOpenRatesFormDialog(true);
          }} size='small' component="label" variant="text" startIcon={<FileUploadIcon />}>
            Upload New Rates
          </Button>
          {/* <Chip label={downloadButtonTitle}
          onClick={() => { downloadFile(id); }}
          icon={<FileDownloadIcon />}
          sx={{ ml: 2 }} /> */}
        </Box>
      </Box>
      {/* <Box sx={{ mt: 2 }}>
        {
          fetchingFiles ? <CircularProgress size={20} /> :
            <Typography>Last Update: {getLastUpdate(id)}</Typography>
        }
      </Box> */}

      <Box sx={{ mt: 2 }}>
        <Accordion variant="outlined" sx={{ border: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          > {
              fetchingFiles ? <CircularProgress size={20} /> :
                <Box sx={{ display: 'flex' }}>
                  <CheckCircleIcon sx={{ color: '#4caf50', mr: 1 }} />
                  <Typography>{getRatesLatestVersion(id) ? getRatesLatestVersion(id).version : ''}</Typography>
                </Box>
            }
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {
                rates && rates.map(rate => {
                  return <><ListItem key={rate.version} secondaryAction={
                    <Box>
                      <Button sx={{ mr: 1 }} onClick={() => {
                        setInsurerRateToDelete(rate);

                        setTimeout(() => {
                          setOpenDeleteDialog(true);
                        }, 200);
                      }}>Delete</Button>
                      <Button sx={{ mr: 1 }} onClick={() => {
                        setSelectedId(id);
                        setSelectedTitle(title);
                        setRatesFormMode('update');
                        setOpenRatesFormDialog(true);
                        setInsurerRate(rate);
                      }}>Edit</Button>
                      <Button onClick={() => {
                        downloadFile(rate.id);
                      }}>Download</Button>
                    </Box>
                  }>
                    <ListItemAvatar>
                      {
                        rate.is_latest ? <CheckCircleIcon sx={{ color: '#4caf50' }} />
                          : <TextSnippetIcon />
                      }
                    </ListItemAvatar>
                    <ListItemText
                      primary={rate.version + (rate.is_latest ? ' (Latest)' : '')}
                    />
                  </ListItem>
                    <Divider /></>
                })
              }
              {/* <ListItem secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <EditIcon />
                </IconButton>
              }>
                <ListItemAvatar>
                  <TextSnippetIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="sept_20_2024 (Latest)"
                />
              </ListItem>
              <Divider />
              <ListItem secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <EditIcon />
                </IconButton>
              }>
                <ListItemAvatar>
                  <TextSnippetIcon />
                </ListItemAvatar>
                <ListItemText
                  primary="oct_20_2023"
                />
              </ListItem> */}
            </List>
          </AccordionDetails>
        </Accordion>
      </Box>

    </Card>
  );
}