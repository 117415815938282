import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import PrivateRoutes from './utils/PrivateRoutes'
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Quotation from './pages/Quotation';
import QuotationList from './pages/QuotationList';
import Register from './pages/Register';
import InsurerRates from './pages/InsurerRates';
import Profile from './pages/Profile';
import AppFiles from './pages/AppFiles';
import ChangePassword from './pages/ChangePassword';
import { ConfigContext } from './utils/ConfigContext';
import Quotation1 from './pages/Quotation1';
import { Box, Button, Typography } from '@mui/material';

function App() {

  const token = localStorage.getItem('quoting-api-token');
  const user = JSON.parse(localStorage.getItem('quoting-current-user'));

  const content = localStorage.getItem('quoting-app-config');
  const configTimestamp = localStorage.getItem('quoting-app-config-timestamp');


  // useEffect(() => {

  // }, []);

  const [auth, setAuth] = useState({
    token,
    user
  });

  let showRefreshConfig = false;

  if (!isJsonString(content)) {
    showRefreshConfig = true;
  }



  const [appConfig, setAppConfig] = useState({
    content: JSON.parse(content),
    configTimestamp
  });

  const subDir = 'quoting';

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <>
      <ConfigContext.Provider value={{ appConfig, setAppConfig }}>
        <AuthContext.Provider value={{ auth, setAuth }}>
          <BrowserRouter basename={subDir}>
            <Routes>
              <Route element={<Login />} path='/login' />
              <Route element={<Login />} path='/' />
              <Route element={<PrivateRoutes />}>
                <Route element={<Register />} path='/create_account' />
                <Route element={<Quotation1 />} path='/quotation' />
                <Route element={<QuotationList />} path='/quotation_list' />
                <Route element={<InsurerRates />} path='/insurer_rates' />
                <Route element={<AppFiles />} path='/app_files' />
                <Route element={<Profile />} path='/profile' />
                <Route element={<ChangePassword />} path='/change_password' />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthContext.Provider>
      </ConfigContext.Provider>
      <ToastContainer />
    </>
  );
}

export default App;
