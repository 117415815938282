import { Badge, Box, Button, Checkbox, FormControlLabel, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";

export default function SelectInsurers({ insurers, setInsurers, sx, fetchQuotationByKey, showResult, pendingTasks, setPendingTasks,
  displayStatus, setDisplayStatus, updateDisplayStatus
}) {
  const [selectAll, setSelectAll] = useState(false);

  const { lifeline, bwho, company, oil_gas, first_choice, ihhp, vumi, my_health_indonesia, my_health_singapore, my_health_vietnam, optimum_global, morgan_price } = insurers;


  function handleSelectAllChange(newValue) {
    const allChecked = checkIfAllChecked();
    const updatedInsurers = {};

    for (const [key, value] of Object.entries(insurers)) {
      updatedInsurers[key] = newValue;
    }

    setInsurers(updatedInsurers);
    setSelectAll(newValue);

    setDisplayStatus(updatedInsurers);
  }

  function checkIfAllChecked() {
    for (const [key, value] of Object.entries(insurers)) {
      if (!value) {
        return false;
      }
    }

    return true;
  }

  function checkIfIndeterminate() {
    const allChecked = checkIfAllChecked();

    if (allChecked) {
      return false;
    }

    for (const [key, value] of Object.entries(insurers)) {
      if (value == true) {
        return true;
      }
    }

    return false;
  }

  const handleChange = (event) => {
    const key = event.target.name;
    const checked = event.target.checked;

    if (checked && showResult) {
      // fetchQuotationByKey(key);
      const _pendingTasks = JSON.parse(JSON.stringify(pendingTasks));


      if (!_pendingTasks.includes(key)) {
        _pendingTasks.push(key);
      }

      setPendingTasks([..._pendingTasks]);
    } else {
      displayStatus[key] = false;
      setDisplayStatus(displayStatus);
    }


    setInsurers({
      ...insurers,
      [key]: checked,
    });
  };

  return (
    <Paper id="select-insurer-card" sx={{ p: 2, ...sx, position: 'sticky', backgroundColor: '#002047', color: '#fff' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography fontWeight={600} fontSize={21} sx={{ mr: 2 }}>Select Insurer / Type of Cover</Typography>
          <Button onClick={() => {
            handleSelectAllChange(false);



          }} sx={{ color: '#fff' }}>Clear Insurers</Button>
        </Box>

        {/* <FormControlLabel
          control={
            <Checkbox checked={selectAll} onChange={(e) => { handleSelectAllChange(e.target.checked) }} indeterminate={checkIfIndeterminate()} />
          }
          label="ALL INSURERS"
        /> */}
      </Box>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 7 }}>
          <FormControlLabel
            labelStyle={{ color: 'white' }}
            iconStyle={{ fill: 'white' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={lifeline} onChange={handleChange} name="lifeline" />
            }
            label="BUPA Lifeline"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={bwho} onChange={handleChange} name="bwho" />
            }
            label="BUPA WHO"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={company} onChange={handleChange} name="company" />
            }
            label="BUPA Company"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={oil_gas} onChange={handleChange} name="oil_gas" />
            }
            label="BUPA Oil & Gas"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={first_choice} onChange={handleChange} name="first_choice" />
            }
            label="BUPA First Choice"
          />

          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={ihhp} onChange={handleChange} name="ihhp" />
            }
            label="BUPA IHHP KSK"
          />

        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 7 }}>
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={vumi} onChange={handleChange} name="vumi"
              />
            }
            label="VUMI"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={my_health_indonesia} onChange={handleChange} name="my_health_indonesia" />
            }
            label="April (MyHealth) Indonesia"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={my_health_singapore} onChange={handleChange} name="my_health_singapore" />
            }
            label="April (MyHealth) Singapore"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={my_health_vietnam} onChange={handleChange} name="my_health_vietnam" />
            }
            label="April (MyHealth) Vietnam"
          />
          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={optimum_global} onChange={handleChange} name="optimum_global" />
            }
            label="AXA - Optimum Global"
          />


          <FormControlLabel
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={morgan_price} onChange={handleChange} name="morgan_price" />
            }
            label="Morgan Price International"
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, fontSize: 14, color: '#e0e0e0' }}>These insurers/products are not yet accessible, as below products are still in the development phase.</Typography>
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="IMG Global Prima"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Liberty proMedico"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Allianz Care - Singapore"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Cigna Global Health Option"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Cigna Care Connect"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Luma - Asia Care Plus (International)"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="Luma - Asia Care Plus (Vietnam)"
          />
          <FormControlLabel
            sx={{ color: '#9e9e9e' }}
            control={
              <Checkbox sx={{
                color: '#fff', '&.Mui-checked': {
                  color: '#fff',
                },
              }} checked={false} readOnly
              />
            }
            label="William Russell"
          />

        </Box>
      </Box>

    </Paper>
  );
}