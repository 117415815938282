import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppBar from './AppBar';
import DrawerHeader from './DrawerHeader';

import { Avatar } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import TuneIcon from '@mui/icons-material/Tune';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotesIcon from '@mui/icons-material/Notes';

import { AuthContext } from '../utils/AuthContext';

import { Link, useNavigate } from 'react-router-dom';
import global from '../utils/Global'
import FolderIcon from '@mui/icons-material/Folder';
import Global from '../utils/Global';



const drawerWidth = 240;

export default function MainHeader({ open, setOpen }) {
    const { auth, setAuth } = useContext(AuthContext);
    const theme = useTheme();

    const navigate = useNavigate()

    const handleDrawerOpen = () => {
        setOpen(true);

        localStorage.setItem('quoting-drawer-open', true);
    };

    const handleDrawerClose = () => {
        setOpen(false);

        localStorage.setItem('quoting-drawer-open', false);
    };

    const goToQuotation = () => {
        navigate('/quotation')
    }

    const goToQuotationList = () => {
        navigate('/quotation_list')
    }

    const goToCreateAccount = () => {
        navigate('/create_account')
    }

    const goToInsurerRates = () => {
        navigate('/insurer_rates')
    }

    const goToAppFiles = () => {
        navigate('/app_files')
    }

    const goToSetting = () => {
        navigate('/change_password')
    }

    const handleLogout = () => {
        localStorage.removeItem('quoting-api-token');
        localStorage.removeItem('quoting-current-user');

        setAuth({
            token: null,
            user: null
        });

        navigate('/login');
    }

    const handleClickLogout = () => {
        handleLogout();
    }



    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Medishure Quoting
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Box sx={{ mb: '13px', width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Avatar sx={{ mb: '10px', width: 80, height: 80 }} alt={auth.user.given_name + " " + auth.user.last_name} src={global.storageURL + '/storage/uploads/' + auth.user.image} />
                    {
                        open ?
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h6' component="div" sx={{ textAlign: 'center' }}>
                                    {auth.user.given_name + " " + auth.user.last_name}
                                </Typography>
                                <Link to="/profile" style={{ textDecoration: 'none' }}>Edit</Link>
                            </Box>
                            : ''
                    }

                </Box>
                <Divider />

                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <List>
                        <ListItem onClick={goToQuotation} key={'Quotation'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ManageSearchIcon />

                                </ListItemIcon>
                                <ListItemText primary='Quotation' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={goToQuotationList} key={'Saved Quotations'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <NotesIcon />

                                </ListItemIcon>
                                <ListItemText primary='Saved Quotations' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>

                        {
                            (auth.user.role_id === Global.ROLE_SUPER_ADMIN || auth.user.role_id === Global.ROLE_ADMIN) &&
                            <ListItem onClick={goToCreateAccount} key={'Create Account'} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AdminPanelSettingsIcon />

                                    </ListItemIcon>
                                    <ListItemText primary='Create Account' sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        }

                        {
                            (auth.user.role_id === Global.ROLE_SUPER_ADMIN || auth.user.role_id === Global.ROLE_ADMIN) &&
                            <ListItem onClick={goToInsurerRates} key={'Insurer Rates'} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <TrendingUpIcon />

                                    </ListItemIcon>
                                    <ListItemText primary='Insurer Rates' sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        }

                        {
                            (auth.user.role_id === Global.ROLE_SUPER_ADMIN || auth.user.role_id === Global.ROLE_ADMIN) &&
                            <ListItem onClick={goToAppFiles} key={'App Files'} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FolderIcon />

                                    </ListItemIcon>
                                    <ListItemText primary='App Files' sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        }
                    </List>

                    <List>
                        <ListItem onClick={handleClickLogout} key={'Logout'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LogoutIcon />

                                </ListItemIcon>
                                <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={goToSetting} key={'Setting'} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <TuneIcon />

                                </ListItemIcon>
                                <ListItemText primary='Setting' sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>

                <Box sx={{ height: '20px' }}>
                </Box>
            </Drawer>
        </>
    )
}
