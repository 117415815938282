import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { forwardRef, useContext, useEffect, useState } from 'react';
import CustomSelect2 from './CustomSelect2';
import { Box, Typography, FormControlLabel, Grid, TextField, InputAdornment, Button, IconButton, Select, MenuItem, colors } from '@mui/material';
import SelectPlanCheckbox from './SelectPlanCheckbox';
import { ConfigContext } from '../utils/ConfigContext';
import TableRatesRow from './TableRatesRow';
import getSafe from '../utils/getSafe';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import domtoimage from 'dom-to-image';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const Vumi_Rates = forwardRef(({ sx, clients, loadingStatus, currency, selectedPlans, setSelectedPlans,
  response, deductibles, setDeductibles, addToPendingTasks,
  discounts, setDiscounts, computedRates, setComputedRates,
  specificAreaOfCoverage, setSpecificAreaOfCoverage, areaOfCoverage,
  setShowImageCopy, selectedOptions, addSelectedOption,
  setViewSelectedOption, specificCurrency, setSpecificCurrency,
  specificPaymentFrequency, setSpecificPaymentFrequency,
  vumiSpecific, setVumiSpecific }, ref) => {
  const { appConfig } = useContext(ConfigContext);

  const id = 'vumi';

  const specific_deductibles = getSafe(() => appConfig.content.specifics.vumi.deductibles);
  const add_ons = getSafe(() => appConfig.content.specifics.vumi.add_ons);
  const coverage_restrictions = getSafe(() => appConfig.content.specifics.vumi.coverage_restrictions);
  const payment_frequency_surcharge = getSafe(() => appConfig.content.specifics.vumi.payment_frequency_surcharge);

  const [coverageRestriction, setCoverageRestriction] = useState('No');
  const [addOn, setAddOn] = useState(getAddOnsLabel()[0]);
  const [addOnValue, setAddOnValue] = useState(0);
  const [deductible, setDeductible] = useState('');

  const plans = ['vumi_basic', 'vumi_standard', 'vumi_superior', 'vumi_ultra', 'vumi_total'];

  const specific_area_of_coverages = getSafe(() => appConfig.content.vumi_area_of_coverage);

  const currencies = getSafe(() => appConfig.content.currencies, []);


  useEffect(() => {
    computedRates[id] = getComputedRates();
    setComputedRates(computedRates);

  }, [response, discounts, coverageRestriction, addOnValue, deductible]);

  useEffect(() => {
    setAddOnValue(getSafe(() => add_ons[addOn], ''));
  }, [addOn]);

  useEffect(() => {
    addSelectedOption(id, 'coverage_restriction', coverageRestriction);
    addSelectedOption(id, 'add_on', addOn);
    addSelectedOption(id, 'specific_area_of_coverage', specificAreaOfCoverage[id]);
    addSelectedOption(id, 'specific_currency', specificCurrency[id]);
    addSelectedOption(id, 'specific_payment_frequency', specificPaymentFrequency[id]);
    addSelectedOption(id, 'deductible', deductibles[id]);
    addSelectedOption(id, 'discount', discounts[id] == '' ? 0 : discounts[id]);
  }, [coverageRestriction, addOn, specificAreaOfCoverage[id], specificCurrency[id], specificPaymentFrequency[id], deductibles[id], discounts[id]]);

  useEffect(() => {
    setViewSelectedOption(id, 'coverage_restriction', setCoverageRestriction);
    setViewSelectedOption(id, 'add_on', setAddOn);

    setViewSelectedOption(id, 'deductible', (option_value) => {
      deductibles[id] = option_value;
      setDeductibles(deductibles);
    });
    setViewSelectedOption(id, 'discount', (option_value) => {
      discounts[id] = option_value;
      setDiscounts(discounts);
    });

    setViewSelectedOption(id, 'specific_area_of_coverage', (option_value) => {
      specificAreaOfCoverage[id] = option_value;
      setSpecificAreaOfCoverage(specificAreaOfCoverage);
    });

    setViewSelectedOption(id, 'specific_currency', (option_value) => {
      specificCurrency[id] = option_value;
      setSpecificCurrency(specificCurrency);
    });

    setViewSelectedOption(id, 'specific_payment_frequency', (option_value) => {
      specificPaymentFrequency[id] = option_value;
      setSpecificPaymentFrequency(specificPaymentFrequency);
    });
  }, [selectedOptions]);

  function getComputedRates() {
    if (!response) return;

    const rates = response.rates;
    const discount = discounts[id];
    const totalPercent = (100 - discount) * 0.01;

    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'basic': computedRate(rates[i].basic, 'basic', rates[i].client.area_of_coverage, rates[i].client.payment_frequency) * totalPercent,
        'standard': computedRate(rates[i].standard, 'standard', rates[i].client.area_of_coverage, rates[i].client.payment_frequency) * totalPercent,
        'superior': computedRate(rates[i].superior, 'superior', rates[i].client.area_of_coverage, rates[i].client.payment_frequency) * totalPercent,
        'ultra': computedRate(rates[i].ultra, 'ultra', rates[i].client.area_of_coverage, rates[i].client.payment_frequency) * totalPercent,
        'total': computedRate(rates[i].total, 'total', rates[i].client.area_of_coverage, rates[i].client.payment_frequency) * totalPercent
      })
    }

    return computedRates;
  }


  function checkIfThereIsAnySelectedPlan() {
    for (let i = 0; i < selectedPlans.length; i++) {
      if (plans.includes(selectedPlans[i])) {
        return true;
      }
    }

    return false;
  }

  function getDeductiblesLabel() {
    const labels = [];

    for (let deductible in specific_deductibles) {
      labels.push(deductible);
    }

    return labels;
  }

  function getAddOnsLabel() {
    const labels = [];

    for (let add_on in add_ons) {
      labels.push(add_on);
    }

    return labels;
  }

  function computeFirstChoiceRates(rates) {
    const computedRates = [];

    for (let i = 0; i < rates.length; i++) {
      computedRates.push({
        'client': rates[i].client,
        'currency': rates[i].currency,
        'basic': computedRate(rates[i].basic, 'basic', rates[i].client.area_of_coverage, rates[i].client.payment_frequency),
        'standard': computedRate(rates[i].standard, 'standard', rates[i].client.area_of_coverage, rates[i].client.payment_frequency),
        'superior': computedRate(rates[i].superior, 'superior', rates[i].client.area_of_coverage, rates[i].client.payment_frequency),
        'ultra': computedRate(rates[i].ultra, 'ultra', rates[i].client.area_of_coverage, rates[i].client.payment_frequency),
        'total': computedRate(rates[i].total, 'total', rates[i].client.area_of_coverage, rates[i].client.payment_frequency)
      })
    }

    return computedRates;
  }

  function computedRate(rate, plan, area_of_coverage, payment_frequency) {
    if (rate === 'Unavailable' || typeof rate === 'string') {
      return rate;
    }

    let total = rate;

    /*

    let _areaOfCoverage = area_of_coverage;

    if (specificAreaOfCoverage[id] === 'parent') {
      _areaOfCoverage = area_of_coverage;
    } else {
      _areaOfCoverage = specificAreaOfCoverage[id];
    }


    const deductiblePercent = specific_deductibles[deductibles[id]][plan];
    const deductible = rate * deductiblePercent;

    total = rate - deductible;

    total += addOnValue;

    if (coverageRestriction === 'Yes') {
      const coverageRestrictionPercent = coverage_restrictions[_areaOfCoverage];
      // console.log(area_of_coverage, coverageRestrictionPercent, coverage_restrictions);
      total = total - (total * coverageRestrictionPercent);
    }

    const payment_frequency_surcharge_percent = payment_frequency_surcharge[payment_frequency];
    const paymentFrequencyDivide = getPaymentFrequencyDivided(payment_frequency);

    total = total + (total * payment_frequency_surcharge_percent);

    total = total / paymentFrequencyDivide;

    */

    return total;
  }

  function getCoverageRestriction(area_of_coverage) {
    let _areaOfCoverage = area_of_coverage;

    if (specificAreaOfCoverage[id] === 'parent') {
      _areaOfCoverage = area_of_coverage;
    } else {
      _areaOfCoverage = specificAreaOfCoverage[id];
    }

    if (coverageRestriction === 'Yes') {
      const coverageRestrictionPercent = coverage_restrictions[_areaOfCoverage];


      return (coverageRestrictionPercent * 100) + '%'
    }


    return '';
  }

  function getPaymentFrequencyDivided(payment_frequency) {
    if (payment_frequency === 'Monthly') {
      return 12;
    } else if (payment_frequency === 'Quarterly') {
      return 4;
    } else if (payment_frequency === 'Semi Annually') {
      return 2;
    } else if (payment_frequency === 'Annually') {
      return 1;
    }

    return 1;
  }

  function onInputDiscountChange(e) {
    let value = e.target.value;

    // Allow only numbers and at most one decimal point
    value = value.replace(/[^0-9.]/g, '');

    // Prevent multiple decimals
    if ((value.match(/\./g) || []).length > 1) {
      value = value.substring(0, value.lastIndexOf('.'));
    }

    // Ensure only two decimal places
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      value = `${integerPart}.${decimalPart.substring(0, 2)}`;
    }

    // Remove leading zeros (but allow "0" or "0.x" scenarios)
    if (value !== '' && value !== '0' && value.startsWith('0') && !value.startsWith('0.')) {
      value = value.replace(/^0+/, '');
    }

    // Limit value to a maximum of 100
    if (parseFloat(value) > 100) {
      value = '100';
    }

    discounts[id] = value;

    setDiscounts({ ...discounts });
  }

  return (
    <Box sx={sx}>
      <Paper sx={{ borderColor: '#e0e0e0', borderWidth: 2, pb: 2 }} variant='outlined'>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#fff', background: '#512da8', px: 2, py: 1 }}>
          <Typography variant='h5'>VUMI Global</Typography>
          <IconButton sx={{ color: '#fff' }}><MoreVertIcon /></IconButton>
        </Box>
        <Box sx={{ p: 2 }}>
          {/* <Grid container alignItems='center'>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Select Plans</Typography>
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_basic' />} label="Basic" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_standard' />} label="Standard" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_superior' />} label="Superior" />
            </Grid>
            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_ultra' />} label="Ultra" />
            </Grid>

            <Grid item xs={1.3}>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_total' />} label="Total" />
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Deductible</Typography>
            </Grid>
            <Grid item xs={1.8}>
              <CustomSelect2
                value={deductibles[id]}
                onChange={(e) => {

                  const val = e.target.value;

                  const _deductibles = JSON.parse(JSON.stringify(deductibles));

                  _deductibles[id] = val;

                  setDeductibles(_deductibles);

                  // addToPendingTasks([id]);
                  setDeductible(val);

                }}
                items={getDeductiblesLabel()}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={1.5}>
              <Typography fontSize={18} fontWeight={600}>Coverage Restriction</Typography>
            </Grid>
            <Grid item xs={1}>
              <CustomSelect2
                value={coverageRestriction}
                onChange={(e) => {
                  setCoverageRestriction(e.target.value);
                }}
                items={['Yes', 'No']}
                disabled={loadingStatus[id]}
              />
            </Grid>

            <Grid item xs={1}>
              <Typography fontSize={18} fontWeight={600}>Add On</Typography>
            </Grid>
            <Grid item xs={1.2}>
              <CustomSelect2
                value={addOn}
                onChange={(e) => {
                  setAddOn(e.target.value);
                }}
                items={getAddOnsLabel()}
                disabled={loadingStatus[id]}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={1.5}><Typography fontSize={18} fontWeight={600}>Area Of Coverage</Typography></Grid>
            <Grid item xs={2}>
              <Select
                size='small'
                value={specificAreaOfCoverage[id]}
                onChange={(e) => {
                  specificAreaOfCoverage[id] = e.target.value;
                  setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                  addToPendingTasks([id]);
                }}
                sx={{ fontSize: 12, height: 40, width: '190px' }}
                fullWidth
                disabled={loadingStatus[id]}
              >
                <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem>
                {
                  specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                    <MenuItem sx={{ display: areaOfCoverage === area_of_coverage ? 'none' : 'block' }} key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                  ))
                }

              </Select>
            </Grid>
            <Grid item xs={1.5} mt={1}><Typography fontSize={18} fontWeight={600}>Renewal/Further Discount</Typography></Grid>
            <Grid item xs={1} mt={1}>
              <TextField
                size='small'
                sx={{ width: '80px', textAlign: 'end' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discounts[id]}
                onChange={onInputDiscountChange}
              />
            </Grid>

            <Grid item xs={2} mt={1}>
              <Typography fontSize={18}>
                Add On Value = <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>${addOnValue}</span>
              </Typography>
            </Grid>
          </Grid> */}

          <Box sx={{ display: 'flex', gap: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontSize={18} fontWeight={600}>Plan Type</Typography>
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_basic' />} label="Basic" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_standard' />} label="Standard" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_superior' />} label="Superior" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_ultra' />} label="Ultra" />
              <FormControlLabel control={<SelectPlanCheckbox selectedPlans={selectedPlans} setSelectedPlans={setSelectedPlans} planId='vumi_total' />} label="Total" />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Deductible Option</Typography>
                <CustomSelect2
                  sx={{ minWidth: 190 }}
                  value={deductibles[id]}
                  onChange={(e) => {

                    const val = e.target.value;

                    const _deductibles = JSON.parse(JSON.stringify(deductibles));

                    _deductibles[id] = val;

                    setDeductibles(_deductibles);

                    setDeductible(val);

                    vumiSpecific['deductible'] = val;
                    setVumiSpecific(vumiSpecific);

                    addToPendingTasks([id]);

                  }}
                  items={getDeductiblesLabel()}
                  disabled={loadingStatus[id]}
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Area Of Coverage</Typography>
                <Select
                  size='small'
                  value={specificAreaOfCoverage[id]}
                  onChange={(e) => {
                    specificAreaOfCoverage[id] = e.target.value;
                    setSpecificAreaOfCoverage({ ...specificAreaOfCoverage });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {/* <MenuItem value={'parent'} sx={{ color: colors.blue[500] }}>{areaOfCoverage}</MenuItem> */}
                  {
                    specific_area_of_coverages && specific_area_of_coverages.map(area_of_coverage => (
                      <MenuItem key={area_of_coverage} value={area_of_coverage}>{area_of_coverage}</MenuItem>
                    ))
                  }

                </Select>
              </Box>

              {/* <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Currency</Typography>
                <Select
                  size='small'
                  value={specificCurrency[id]}
                  onChange={(e) => {
                    specificCurrency[id] = e.target.value;
                    setSpecificCurrency({ ...specificCurrency });

                    setDeductibles(prev => {
                      return {
                        ...prev,
                        [id]: getSafe(() => appConfig.content.deductibles.vumi['USD'][0])
                      };
                    });


                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    currencies && currencies.map(currency => (
                      <MenuItem key={currency} value={currency}>{currency}</MenuItem>
                    ))
                  }

                </Select>
              </Box> */}
              
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Payment Frequency</Typography>
                <Select
                  size='small'
                  value={specificPaymentFrequency[id]}
                  onChange={(e) => {
                    specificPaymentFrequency[id] = e.target.value;
                    setSpecificPaymentFrequency({ ...specificPaymentFrequency });

                    addToPendingTasks([id]);
                  }}
                  sx={{ fontSize: 12, height: 40, width: '190px', minWidth: 190 }}
                  fullWidth
                  disabled={loadingStatus[id]}
                >
                  {
                    ["Annually", "Semi Annually", "Quarterly", "Monthly"].map(payment_frequency => (
                      <MenuItem key={payment_frequency} value={payment_frequency}>{payment_frequency}</MenuItem>
                    ))
                  }

                </Select>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Coverage Restriction</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CustomSelect2
                    sx={{ minWidth: 190 }}
                    value={coverageRestriction}
                    onChange={(e) => {
                      setCoverageRestriction(e.target.value);

                      vumiSpecific['coverage_restriction'] = e.target.value;
                      setVumiSpecific(vumiSpecific);

                      addToPendingTasks([id]);
                    }}
                    items={['Yes', 'No']}
                    disabled={loadingStatus[id]}
                  />
                  <Typography fontSize={18}>
                    <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>{getCoverageRestriction(areaOfCoverage)}</span>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Add On</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CustomSelect2
                    sx={{ minWidth: 190 }}
                    value={addOn}
                    onChange={(e) => {
                      setAddOn(e.target.value);

                      vumiSpecific['add_on'] = e.target.value;
                      setVumiSpecific(vumiSpecific);

                      addToPendingTasks([id]);
                    }}
                    items={getAddOnsLabel()}
                    disabled={loadingStatus[id]}
                  />
                  <Typography fontSize={18}>
                    <span display='inline-block' style={{ fontSize: 18, color: '#002047', fontWeight: 600 }}>${addOnValue}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', alignItems: 'start' }}>
                <Typography fontSize={18} fontWeight={600} sx={{ width: 200 }}>Renewal/Further Discount</Typography>
                <TextField
                  size='small'
                  sx={{ width: '80px', textAlign: 'end' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  value={discounts[id]}
                  onChange={onInputDiscountChange}
                  autoComplete="off"
                />
              </Box>
            </Box>
          </Box>



          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button
              size='small'
              sx={{ mb: 1 }}
              onClick={() => {
                var node = document.getElementById('table-vumi-rates');

                node.style = 'width: auto;';

                domtoimage.toBlob(node).then(function (blob) {
                  // Create ClipboardItem with blob and its type, and add to an array
                  const data = [new ClipboardItem({ [blob.type]: blob })];
                  // Write the data to the clipboard
                  navigator.clipboard.write(data);

                  setShowImageCopy(true);

                  node.style = 'width: 100%;';
                })
                  .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                    node.style = 'width: 100%;';
                  });
              }}
              endIcon={<ContentCopyIcon />}
            >Copy Image</Button>
          </div>

          <TableContainer component={Paper}>
            <Table id='table-vumi-rates' size='small' sx={{ minWidth: 650 }}>
              <TableHead sx={{ background: '#512da8', color: '#fff' }}>
                <TableRow>
                  <TableCell sx={{ width: 260, color: '#fff', fontWeight: 600 }}>Plan</TableCell>
                  {
                    clients.map((client, idx) => {
                      if (client.full_name.trim() === '') {
                        return <TableCell sx={{ width: 200, fontWeight: 600, color: '#fff' }} key={idx}>_</TableCell>
                      }

                      return <TableCell sx={{ width: 200, color: '#fff', fontWeight: 600 }} key={idx}><div>{client.full_name} ({client.age})</div><div>{client.country_of_residence}</div></TableCell>
                    })
                  }
                  <TableCell sx={{ color: '#fff', fontWeight: 600 }}>Subtotal</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                <TableRatesRow
                  planId='vumi_basic'
                  planName='basic'
                  planLabel='Basic'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeFirstChoiceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('vumi_basic')}
                />
                <TableRatesRow
                  planId='vumi_standard'
                  planName='standard'
                  planLabel='Standard'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeFirstChoiceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('vumi_standard')}
                />
                <TableRatesRow
                  planId='vumi_superior'
                  planName='superior'
                  planLabel='Superior'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeFirstChoiceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('vumi_superior')}
                />
                <TableRatesRow
                  planId='vumi_ultra'
                  planName='ultra'
                  planLabel='Ultra'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeFirstChoiceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('vumi_ultra')}
                />
                <TableRatesRow
                  planId='vumi_total'
                  planName='total'
                  planLabel='Total'
                  clients={clients}
                  selectedPlans={selectedPlans}
                  bgColor='#b39ddb'
                  loading={loadingStatus[id]}
                  rates={response ? computeFirstChoiceRates(response.rates) : []}
                  currency={currency}
                  discount={discounts[id]}
                  visible={selectedPlans.includes('vumi_total')}
                />
                {/* {
                  checkIfThereIsAnySelectedPlan() == false &&
                  <TableRow>
                    <TableCell>No Plan Selected</TableCell>
                  </TableRow>
                } */}
              </TableBody>
            </Table>

          </TableContainer>


        </Box>


      </Paper>

    </Box>
  );
});

export default Vumi_Rates;