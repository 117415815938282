import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../utils/AuthContext';

export default function QuotationDataFormDialog({ mode, quotationData, quotationName, setQuotationName, open, setOpen, 
  callback, refInputTable }) {
  const { auth } = useContext(AuthContext);

  const [_quotationName, _setQuotationName] = useState('');


  useEffect(() => {
    const _clients = refInputTable.current.getClientData();

    if (mode == 'add') {
      if (_clients.length > 0) {
        _setQuotationName(_clients[0].full_name + " Quotation");
      }
    } else if (mode == 'update') {
      _setQuotationName(quotationData.name);
    }

    // setTimeout(() => {
    //   if (open) {
    //     document.getElementById("quotation-name").value = quotationName;
    //   }
      
    // }, 500);
    
    
  }, [open]);


  const handleClose = () => {
    setOpen(false);
  };

  function getTitle() {
    if (mode == 'add') {
      return 'Save Quotation';
    } else if (mode == 'update') {
      return 'Update Quotation';
    }
    
    return '';
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
          <Box minWidth={400}>
            <TextField
              value={_quotationName}
              onChange={(event) => {
                _setQuotationName(event.target.value);
              }}
              // defaultValue={quotationName}
              autoFocus
              margin="dense"
              id="quotation-name"
              label="Quotation Name"
              fullWidth
              size='small'
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => {
            setQuotationName(_quotationName);
            callback(_quotationName);
          }}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}